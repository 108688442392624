import { EventEmitter } from 'events';
import { isDeepEqual, parseAttributes, UriBuilder } from './util';
import { Logger } from './logger';

import { Conversation } from './conversation';
import { Session } from './session';
import { McsClient } from 'twilio-mcs-client';
import { Media } from './media';
import { Participant } from './participant';
import { AggregatedDeliveryReceipt } from './aggregateddeliveryreceipt';
import { validateTypesAsync, literal } from 'twilio-sdk-type-validator';
import { Network } from './services/network';
import { RestPaginator } from './restpaginator';
import { DetailedDeliveryReceipt } from './detaileddeliveryreceipt';
import { Paginator } from './interfaces/paginator';

const log = Logger.scope('Message');

namespace Message {
  export type Type = 'text' | 'media';
}

interface MessageState {
  sid: string;
  index: number;
  author?: string;
  subject?: string;
  body: string;
  dateUpdated: Date;
  lastUpdatedBy: string;
  attributes: Object;
  timestamp: Date;
  type: Message.Type;
  media?: Media;
  participantSid?: string;
  aggregatedDeliveryReceipt?: AggregatedDeliveryReceipt;
}

export interface MessageServices {
  session: Session;
  mcsClient: McsClient;
  network: Network;
}

namespace Message {
  export type UpdateReason = 'body' | 'lastUpdatedBy' | 'dateCreated' | 'dateUpdated' | 'attributes' | 'author' |
    'deliveryReceipt' | 'subject';

  export interface UpdatedEventArgs {
    message: Message;
    updateReasons: Message.UpdateReason[];
  }
}

/**
 * @classdesc A Message represents a Message in a Conversation.
 * @property {String} author - The name of the user that sent Message
 * @property {String|null} subject - Message subject. Used only in email conversations
 * @property {String} body - The body of the Message. Is null if Message is Media Message
 * @property {any} attributes - Message custom attributes
 * @property {Conversation} conversation - Conversation Message belongs to
 * @property {Date} dateCreated - When Message was created
 * @property {Date} dateUpdated - When Message was updated
 * @property {Number} index - Index of Message in the Conversation's messages list
 *  By design of the conversations system the message indices may have arbitrary gaps between them,
 *  that does not necessarily mean they were deleted or otherwise modified - just that
 *  messages may have non-contiguous indices even if they are sent immediately one after another.
 *
 *  Trying to use indices for some calculations is going to be unreliable.
 *
 *  To calculate the number of unread messages it is better to use the read horizon API.
 *  See {@link Conversation#getUnreadMessagesCount} for details.
 *
 * @property {String} lastUpdatedBy - Identity of the last user that updated Message
 * @property {Media} media - Contains Media information (if present)
 * @property {String} participantSid - Authoring Participant's server-assigned unique identifier
 * @property {String} sid - The server-assigned unique identifier for Message
 * @property {'text' | 'media'} type - Type of message: 'text' or 'media'
 * @property {AggregatedDeliveryReceipt | null} aggregatedDeliveryReceipt - Aggregated information about
 *   Message delivery statuses across all {@link Participant}s of a {@link Conversation}.
 * @fires Message#updated
 */
class Message extends EventEmitter {

  public readonly conversation: Conversation;
  private services: MessageServices;
  private state: MessageState;

  /**
   * The update reason for <code>updated</code> event emitted on Message
   * @typedef {('body' | 'lastUpdatedBy' | 'dateCreated' | 'dateUpdated' | 'attributes' | 'author' |
   *   'deliveryReceipt' | 'subject')} Message#UpdateReason
   */

  constructor(conversation: Conversation, services: MessageServices, index: number, data: any) {
    super();

    this.conversation = conversation;
    this.services = services;

    this.state = {
      sid: data.sid,
      index: index,
      author: data.author == null ? null : data.author,
      subject: data.subject == null ? null : data.subject,
      body: data.text,
      timestamp: data.timestamp ? new Date(data.timestamp) : null,
      dateUpdated: data.dateUpdated ? new Date(data.dateUpdated) : null,
      lastUpdatedBy: data.lastUpdatedBy ? data.lastUpdatedBy : null,
      attributes: parseAttributes(data.attributes, `Got malformed attributes for the message ${data.sid}`, log),
      type: data.type ? data.type : 'text',
      media: (data.type && data.type === 'media' && data.media)
        ? new Media(data.media, this.services) : null,
      participantSid: data.memberSid == null ? null : data.memberSid,
      aggregatedDeliveryReceipt: data.delivery ? new AggregatedDeliveryReceipt(data.delivery) : null
    };
  }

  public get sid(): string { return this.state.sid; }

  public get author(): string { return this.state.author; }

  public get subject(): string | null { return this.state.subject; }

  public get body(): string {
    if (this.type === 'media') { return null; }
    return this.state.body;
  }

  public get dateUpdated(): Date { return this.state.dateUpdated; }

  public get index(): number { return this.state.index; }

  public get lastUpdatedBy(): string { return this.state.lastUpdatedBy; }

  public get dateCreated(): Date { return this.state.timestamp; }

  public get attributes(): Object { return this.state.attributes; }

  public get type(): Message.Type { return this.state.type; }

  public get media(): Media { return this.state.media; }

  public get participantSid(): string { return this.state.participantSid; }

  public get aggregatedDeliveryReceipt(): AggregatedDeliveryReceipt | null { return this.state.aggregatedDeliveryReceipt; }

  _update(data) {
    let updateReasons: Message.UpdateReason[] = [];

    if ((data.text || ((typeof data.text) === 'string')) && data.text !== this.state.body) {
      this.state.body = data.text;
      updateReasons.push('body');
    }

    if (data.subject && data.subject !== this.state.subject) {
      this.state.subject = data.subject;
      updateReasons.push('subject');
    }

    if (data.lastUpdatedBy && data.lastUpdatedBy !== this.state.lastUpdatedBy) {
      this.state.lastUpdatedBy = data.lastUpdatedBy;
      updateReasons.push('lastUpdatedBy');
    }

    if (data.author && data.author !== this.state.author) {
      this.state.author = data.author;
      updateReasons.push('author');
    }

    if (data.dateUpdated &&
      new Date(data.dateUpdated).getTime() !== (this.state.dateUpdated && this.state.dateUpdated.getTime())) {
      this.state.dateUpdated = new Date(data.dateUpdated);
      updateReasons.push('dateUpdated');
    }

    if (data.timestamp &&
      new Date(data.timestamp).getTime() !== (this.state.timestamp && this.state.timestamp.getTime())) {
      this.state.timestamp = new Date(data.timestamp);
      updateReasons.push('dateCreated');
    }

    let updatedAttributes = parseAttributes(data.attributes, `Got malformed attributes for the message ${this.sid}`, log);
    if (!isDeepEqual(this.state.attributes, updatedAttributes)) {
      this.state.attributes = updatedAttributes;
      updateReasons.push('attributes');
    }

    let updatedAggregatedDelivery = data.delivery;
    let currentAggregatedDelivery = this.state.aggregatedDeliveryReceipt;
    let isUpdatedAggregateDeliveryValid = !!updatedAggregatedDelivery && !!updatedAggregatedDelivery.total &&
      !!updatedAggregatedDelivery.delivered && !!updatedAggregatedDelivery.failed && !!updatedAggregatedDelivery.read &&
      !!updatedAggregatedDelivery.sent && !!updatedAggregatedDelivery.undelivered;
    if (isUpdatedAggregateDeliveryValid) {
      if (!currentAggregatedDelivery) {
        this.state.aggregatedDeliveryReceipt = new AggregatedDeliveryReceipt(updatedAggregatedDelivery);
        updateReasons.push('deliveryReceipt');
      } else if (!currentAggregatedDelivery._isEquals(updatedAggregatedDelivery)) {
        currentAggregatedDelivery._update(updatedAggregatedDelivery);
        updateReasons.push('deliveryReceipt');
      }
    }

    if (updateReasons.length > 0) {
      this.emit('updated', { message: this, updateReasons: updateReasons });
    }
  }

  /**
   * Get Participant who is author of the Message
   * @returns {Promise<Participant>}
   */
  async getParticipant(): Promise<Participant> {
    let participant: Participant = null;
    if (this.state.participantSid) {
      participant = await this.conversation.getParticipantBySid(this.participantSid)
        .catch(() => {
          log.debug('Participant with sid "' + this.participantSid + '" not found for message ' + this.sid);
          return null;
        });
    }
    if (!participant && this.state.author) {
      participant = await this.conversation.getParticipantByIdentity(this.state.author)
        .catch(() => {
          log.debug('Participant with identity "' + this.author + '" not found for message ' + this.sid);
          return null;
        });
    }
    if (participant) {
      return participant;
    }
    let errorMesage = 'Participant with ';
    if (this.state.participantSid) {
      errorMesage += 'SID \'' + this.state.participantSid + '\' ';
    }
    if (this.state.author) {
      if (this.state.participantSid) {
        errorMesage += 'or ';
      }
      errorMesage += 'identity \'' + this.state.author + '\' ';
    }
    if (errorMesage === 'Participant with ') {
      errorMesage = 'Participant ';
    }
    errorMesage += 'was not found';
    throw new Error(errorMesage);
  }

  /**
   * Get delivery receipts of the message
   * @returns {Promise<DetailedDeliveryReceipt[]>}
   */
  async getDetailedDeliveryReceipts(): Promise<DetailedDeliveryReceipt[]> {
    let paginator: Paginator<DetailedDeliveryReceipt> = await this._getDetailedDeliveryReceiptsPaginator();
    let detailedDeliveryReceipts: DetailedDeliveryReceipt[] = [];

    while (true) {
      detailedDeliveryReceipts = [...detailedDeliveryReceipts, ...paginator.items];

      if (!paginator.hasNextPage) {
        break;
      }

      paginator = await paginator.nextPage();
    }

    return detailedDeliveryReceipts;
  }

  /**
   * Remove the Message.
   * @returns {Promise<Message>}
   */
  async remove(): Promise<Message> {
    await this.services.session.addCommand('deleteMessage', {
      channelSid: this.conversation.sid,
      messageIdx: this.index.toString()
    });
    return this;
  }

  /**
   * Edit message body.
   * @param {String} body - new body of Message.
   * @returns {Promise<Message>}
   */
  @validateTypesAsync('string')
  async updateBody(body: string): Promise<Message> {
    await this.services.session.addCommand('editMessage', {
      channelSid: this.conversation.sid,
      messageIdx: this.index.toString(),
      text: body
    });

    return this;
  }

  /**
   * Edit message attributes.
   * @param {any} attributes new attributes for Message.
   * @returns {Promise<Message>}
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  async updateAttributes(attributes: any): Promise<Message> {
    await this.services.session.addCommand('editMessageAttributes', {
      channelSid: this.conversation.sid,
      messageIdx: this.index,
      attributes: JSON.stringify(attributes)
    });

    return this;
  }

  private async _getDetailedDeliveryReceiptsPaginator(options?: {
    pageToken?: string,
    pageSize?: number
  }): Promise<Paginator<DetailedDeliveryReceipt>> {
    let links = await this.services.session.getSessionLinks();
    let messagesReceiptsUrl = links.messagesReceiptsUrl.replace('%s', this.conversation.sid).replace('%s', this.sid);
    let url = new UriBuilder(messagesReceiptsUrl).arg('PageToken', options?.pageToken).arg('PageSize', options?.pageSize).build();
    let response = await this.services.network.get(url);

    return new RestPaginator<DetailedDeliveryReceipt>(response.body.delivery_receipts.map(x => new DetailedDeliveryReceipt(x))
      , (pageToken, pageSize) => this._getDetailedDeliveryReceiptsPaginator({ pageToken, pageSize })
      , response.body.meta.previous_token
      , response.body.meta.next_token);
  }
}

export { Message };

/**
 * Fired when the Message's properties or body has been updated.
 * @event Message#updated
 * @type {Object}
 * @property {Message} message - Updated Message
 * @property {Message#UpdateReason[]} updateReasons - Array of Message's updated event reasons
 */
