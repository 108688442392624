const TYPING_PATH = '/v1/typing';
const TYPING_TIMEOUT = 5;
const HTTP_CACHE_LIFETIME = 'PT5S';
const CONSUMPTION_HORIZON_SENDING_INTERVAL = 'PT5S';
const USER_INFOS_TO_SUBSCRIBE = 100;

const MINIMUM_RETRY_DELAY = 1000;
const MAXIMUM_RETRY_DELAY = 4000;
const MAXIMUM_ATTEMPTS_COUNT = 3;
const RETRY_WHEN_THROTTLED = true;

class Configuration {
  token: string;
  public readonly typingIndicatorTimeoutOverride?: number;
  public readonly httpCacheIntervalOverride?: string;
  public readonly consumptionReportIntervalOverride?: number;
  public readonly userInfosToSubscribeOverride?: number;
  public readonly retryWhenThrottledOverride?: boolean;
  public readonly backoffConfigOverride?: any;
  public readonly typingIndicatorUri: string;
  public readonly productId: string;
  private baseUrl: string;
  private region: string;

  constructor(options: any) {
    options = options || {};
    let constructorOptions = options.Chat || options.IPMessaging || options || {};
    this.region = constructorOptions.region || options.region;
    this.baseUrl = constructorOptions.apiUri || constructorOptions.typingUri ||
      ((!this.region || this.region === 'us1') ? 'https://aim.twilio.com' : `https://aim.${this.region}.twilio.com`);
    this.typingIndicatorUri = this.baseUrl + TYPING_PATH;
    this.typingIndicatorTimeoutOverride = constructorOptions.typingIndicatorTimeoutOverride;
    this.httpCacheIntervalOverride = constructorOptions.httpCacheIntervalOverride;
    this.consumptionReportIntervalOverride = constructorOptions.consumptionReportIntervalOverride;
    this.userInfosToSubscribeOverride = constructorOptions.userInfosToSubscribeOverride;
    this.retryWhenThrottledOverride = constructorOptions.retryWhenThrottledOverride;
    this.backoffConfigOverride = constructorOptions.backoffConfigOverride;
    this.productId = options.productId;
  }

  public get typingIndicatorTimeoutDefault(): number { return TYPING_TIMEOUT * 1000; }

  public get httpCacheIntervalDefault(): string { return HTTP_CACHE_LIFETIME; }

  public get consumptionReportIntervalDefault(): string { return CONSUMPTION_HORIZON_SENDING_INTERVAL; }

  public get userInfosToSubscribeDefault(): number { return USER_INFOS_TO_SUBSCRIBE; }

  public get retryWhenThrottledDefault(): boolean { return RETRY_WHEN_THROTTLED; }

  public get backoffConfigDefault(): any {
    return {
      min: MINIMUM_RETRY_DELAY,
      max: MAXIMUM_RETRY_DELAY,
      maxAttemptsCount: MAXIMUM_ATTEMPTS_COUNT
    };
  }
}

export { Configuration };
