'use strict';
import { Paginator } from './interfaces/paginator';

interface PaginatorState {
  source: any;
  nextToken: any;
  prevToken: any;
  items: any;
}

/**
 * @class Paginator
 * @classdesc Pagination helper class
 *
 * @property {Array} items Array of elements on current page
 * @property {boolean} hasNextPage Indicates the existence of next page
 * @property {boolean} hasPrevPage Indicates the existence of previous page
 */
class RestPaginator<T> implements Paginator<T> {
  private state: PaginatorState;

  public get hasNextPage(): boolean { return !!this.state.nextToken; }

  public get hasPrevPage(): boolean { return !!this.state.prevToken; }

  public get items(): Array<T> { return this.state.items; }

  /*
  * @constructor
  * @param {Array} items Array of element for current page
  * @param {Object} params
  * @private
  */
  constructor(items, source, prevToken, nextToken) {

    this.state = {
      prevToken,
      nextToken,
      source,
      items
    };
  }

  nextPage(): Promise<RestPaginator<T>> {
    return this.hasNextPage ? this.state.source(this.state.nextToken) : Promise.reject(new Error('No next page'));
  }

  prevPage(): Promise<RestPaginator<T>> {
    return this.hasPrevPage ? this.state.source(this.state.prevToken) : Promise.reject(new Error('No previous page'));
  }
}

export { RestPaginator };
