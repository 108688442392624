import * as loglevelLog from 'loglevel';

function prepareLine(prefix, args) {
  return [`${new Date().toISOString()} Conversations ${prefix}:`].concat(Array.from(args));
}

const log: loglevelLog.Logger = loglevelLog.getLogger('twilio-conversations'); // twilio-conversations is used by Flex SDK. Please DO NOT change

class Logger {
  private prefix: string = '';

  private constructor(prefix: string) {
    this.prefix = prefix !== null && prefix !== undefined && prefix.length > 0
      ? prefix + ' '
      : '';
  }

  static scope(prefix: string): Logger {
    return new Logger(prefix);
  }

  setLevel(level: any) {
    log.setLevel(level);
  }

  static setLevel(level: any) {
    log.setLevel(level);
  }

  trace(...args) { log.trace.apply(null, prepareLine(this.prefix + 'T', args)); }

  debug(...args) { log.debug.apply(null, prepareLine(this.prefix + 'D', args)); }

  info(...args) { log.info.apply(null, prepareLine(this.prefix + 'I', args)); }

  warn(...args) { log.warn.apply(null, prepareLine(this.prefix + 'W', args)); }

  error(...args) { log.error.apply(null, prepareLine(this.prefix + 'E', args)); }

  static trace(...args) { log.trace.apply(null, prepareLine('T', args)); }

  static debug(...args) { log.debug.apply(null, prepareLine('D', args)); }

  static info(...args) { log.info.apply(null, prepareLine('I', args)); }

  static warn(...args) { log.warn.apply(null, prepareLine('W', args)); }

  static error(...args) { log.error.apply(null, prepareLine('E', args)); }

}

export { Logger };
