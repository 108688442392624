import { Network } from './services/network';
import { Session } from './session';
import { UriBuilder } from './util/index';
import { RestPaginator } from './restpaginator';
import { SyncListDescriptor } from './synclistdescriptor';

export interface SyncListServices {
  session: Session;
  network: Network;
}

/**
 * Provides async pagination interface for sync list
 *
 * @property {Network} network Network transport
 * @property {string} syncListUrl Url to the sync list endpoint
 */
class SyncList {

  private readonly services: SyncListServices;

  /**
   * @param {SyncListServices} services - services needed for SyncList service
   * @private
   */
  constructor(services: SyncListServices) {
    this.services = services;
  }

  public async getPage(args?): Promise<RestPaginator<SyncListDescriptor>> {
    args = args || {};
    let links = await this.services.session.getSessionLinks();
    const url = new UriBuilder(links.syncListUrl).arg('PageToken', args.pageToken).build();
    let response = await this.services.network.get(url);
    return new RestPaginator<SyncListDescriptor>(response.body.channels.map(x => new SyncListDescriptor(x))
      , pageToken => this.getPage({ pageToken })
      , response.body.meta.previous_token
      , response.body.meta.next_token);
  }
}

export { SyncList };
