import { Session } from '../session';
import { SessionError } from '../sessionerror';

export interface ReadHorizonServices {
  session: Session;
}

class ConsumptionReportRequest {
  entry: ConsumptionReportEntry;
  promises: ReadHorizonPromise[];
}

class ConsumptionReportEntry {
  channelSid: string;
  messageIdx: number;
}

class ReadHorizonPromise {
  resolve: any;
  reject: any;
}

interface ConsumptionReportResponseEntry {
  channelSid: string;
  unreadMessagesCount?: number;
}

/**
 * @classdesc Provides read horizon management functionality
 */
class ReadHorizon {
  private readonly services: ReadHorizonServices;
  private readonly readHorizonRequests: Map<string, ConsumptionReportRequest>;
  private readHorizonUpdateTimer: any;

  constructor(services: ReadHorizonServices) {
    this.services = services;
    this.readHorizonRequests = new Map();
    this.readHorizonUpdateTimer = null;
  }

  private getReportInterval(): Promise<number> {
    return this.services.session.getConsumptionReportInterval().then(
      seconds => seconds * 1000);
  }

  private delayedSendReadHorizon(delay) {
    if (this.readHorizonUpdateTimer !== null) {
      return;
    }

    this.sendConsumptionReport(true);

    this.readHorizonUpdateTimer = setTimeout(() => {
      this.sendConsumptionReport(false);
    }, delay);

  }

  private sendConsumptionReport(keepTimer: boolean) {
    let reports = [];
    let promises = new Map<string, ReadHorizonPromise[]>();
    this.readHorizonRequests.forEach((request, conversationSid) => {
      reports.push(request.entry);
      promises.set(conversationSid, request.promises);
    });
    if (reports.length > 0) {
      this.services.session.addCommand('consumptionReportV2', { report: reports })
          .then(response => this.processConsumptionReportResponse(response, promises))
          .catch(err => this.processConsumptionReportError(err, promises));
    }
    if (!keepTimer) {
      this.readHorizonUpdateTimer = null;
    }
    this.readHorizonRequests.clear();

  }

  private processConsumptionReportResponse(response: any, promises: Map<string, ReadHorizonPromise[]>) {
    if (response && response.report && Array.isArray(response.report) && response.report.length > 0) {
      response.report.forEach(entry => {
        let responseEntry = entry as ConsumptionReportResponseEntry;
        if (promises.has(responseEntry.channelSid)) {
          let unreadMessagesCount = null;
          if ((typeof responseEntry.unreadMessagesCount !== 'undefined') && responseEntry.unreadMessagesCount != null) {
            unreadMessagesCount = responseEntry.unreadMessagesCount;
          }
          promises.get(responseEntry.channelSid).forEach(promise => promise.resolve(unreadMessagesCount));
          promises.delete(responseEntry.channelSid);
        }
      });
    }

    this.processConsumptionReportError(new SessionError('Error while setting LastReadMessageIndex', null), promises);
  }

  private processConsumptionReportError(err: SessionError, promises: Map<string, ReadHorizonPromise[]>) {
    promises.forEach(conversationPromises => conversationPromises.forEach(promise => promise.reject(err)));
  }

  /**
   * Updates read horizon value without any checks
   */
  updateLastReadMessageIndexForConversation(conversationSid: string, messageIdx: number): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.addPendingConsumptionHorizonRequest(conversationSid, { channelSid: conversationSid, messageIdx }, { resolve, reject });
      this.getReportInterval().then(delay => this.delayedSendReadHorizon(delay));
    });
  }

  /**
   * Move read horizon forward
   */
  advanceLastReadMessageIndexForConversation(
    conversationSid: string,
    messageIdx: number,
    currentConversationLastReadIndex: number
  ): Promise<number> {
    let currentHorizon = this.readHorizonRequests.get(conversationSid);
    return new Promise<number>((resolve, reject) => {
      if (currentHorizon && currentHorizon.entry) {
        if (currentHorizon.entry.messageIdx >= messageIdx) {
          this.addPendingConsumptionHorizonRequest(conversationSid, currentHorizon.entry, { resolve, reject });
        } else {
          this.addPendingConsumptionHorizonRequest(conversationSid, { channelSid: conversationSid, messageIdx }, { resolve, reject });
        }
      } else {
        if ((currentConversationLastReadIndex !== null) && messageIdx < currentConversationLastReadIndex) {
          this.addPendingConsumptionHorizonRequest(
            conversationSid,
            { channelSid: conversationSid, messageIdx: currentConversationLastReadIndex },
            { resolve, reject });
        } else {
          this.addPendingConsumptionHorizonRequest(conversationSid, { channelSid: conversationSid, messageIdx }, { resolve, reject });
        }
      }
      this.getReportInterval().then(delay => this.delayedSendReadHorizon(delay));
    });
  }

  private addPendingConsumptionHorizonRequest(conversationSid: string, entry: ConsumptionReportEntry, promise: ReadHorizonPromise) {
    if (this.readHorizonRequests.has(conversationSid)) {
      let request = this.readHorizonRequests.get(conversationSid);
      request.entry = entry;
      request.promises.push(promise);
    } else {
      this.readHorizonRequests.set(conversationSid, { entry, promises: [promise] });
    }
  }
}

export { ReadHorizon };
