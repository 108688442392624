import { EventEmitter } from 'events';
import { Logger } from '../logger';
import { Conversation } from '../conversation';

import { SyncMap, SyncClient } from 'twilio-sync';
import { Session } from '../session';
import { SyncList } from '../synclist';
import { Users } from './users';
import { Network } from '../services/network';
import { TypingIndicator } from '../services/typingindicator';
import { ReadHorizon } from '../services/readhorizon';
import { McsClient } from 'twilio-mcs-client';
import { Deferred } from '../util/deferred';
import { Participant } from '../participant';
import { Message } from '../message';
import { isDeepEqual, parseAttributes, parseTime, UriBuilder } from '../util';

const log = Logger.scope('Conversations');

export interface ConversationsServices {
  session: Session;
  syncClient: SyncClient;
  syncList: SyncList;
  users: Users;
  typingIndicator: TypingIndicator;
  readHorizon: ReadHorizon;
  network: Network;
  mcsClient: McsClient;
}

/**
 * Represents conversations collection
 * {@see Conversation}
 */
class Conversations extends EventEmitter {

  private services: ConversationsServices;
  public readonly conversations: Map<string, Conversation>;
  private readonly thumbstones: Set<string>; // sids
  private syncListFetched: boolean;
  public readonly syncListRead: Deferred<boolean>;

  constructor(services: ConversationsServices) {
    super();
    this.services = services;
    this.conversations = new Map<string, Conversation>();
    this.thumbstones = new Set<string>();
    this.syncListFetched = false;
    this.syncListRead = new Deferred<boolean>();
  }

  private getMap(): Promise<SyncMap> {
    return this.services.session.getMyConversationsId()
               .then(name => this.services.syncClient.map({ id: name, mode: 'open_existing' }));
  }

  /**
   * Add conversation to server
   * @private
   * @returns {Promise<Conversation>} Conversation
   */
  async addConversation(options): Promise<Conversation> {
    let attributes;
    if (typeof options.attributes === 'undefined') {
      attributes = {};
    } else {
      attributes = options.attributes;
    }

    let response = await this.services.session.addCommand('createConversation', {
      friendlyName: options.friendlyName,
      uniqueName: options.uniqueName,
      attributes: JSON.stringify(attributes)
    }) as Object;

    let conversationSid = 'conversationSid' in response ? response['conversationSid'] : null;
    let conversationDocument = 'conversation' in response ? response['conversation'] : null;

    let existingConversation = this.conversations.get(conversationSid);
    if (existingConversation) {
      await existingConversation._subscribe();
      return existingConversation;
    }

    let conversation = new Conversation(this.services,
      {
        channel: conversationDocument,

        entityName: null,
        uniqueName: null,
        attributes: null,
        createdBy: null,
        friendlyName: null,
        lastConsumedMessageIndex: null,
        dateCreated: null,
        dateUpdated: null
      },
      conversationSid);

    this.conversations.set(conversation.sid, conversation);
    this.registerForEvents(conversation);

    await conversation._subscribe();
    this.emit('conversationAdded', conversation);
    return conversation;
  }

  /**
   * Fetch conversations list and instantiate all necessary objects
   */
  fetchConversations() {
    this.getMap()
        .then(async map => {
          map.on('itemAdded', args => {
            log.debug('itemAdded: ' + args.item.key);
            this.upsertConversation('sync', args.item.key, args.item.data);
          });

          map.on('itemRemoved', args => {
            log.debug('itemRemoved: ' + args.key);
            let sid = args.key;
            if (!this.syncListFetched) {
              this.thumbstones.add(sid);
            }
            let conversation = this.conversations.get(sid);
            if (conversation) {
              if (conversation && conversation.status === 'joined' /*|| conversation.status === 'invited'*/) {
                conversation._setStatus('notParticipating', 'sync');
                this.emit('conversationLeft', conversation);
              }

              this.conversations.delete(sid);
              this.emit('conversationRemoved', conversation);
              conversation.emit('removed', conversation);
            }
          });

          map.on('itemUpdated', args => {
            log.debug('itemUpdated: ' + args.item.key);
            this.upsertConversation('sync', args.item.key, args.item.data);
          });

          let upserts = [];

          let paginator = await this.services.syncList.getPage();
          let items = paginator.items;
          items.forEach(item => {
            upserts.push(this.upsertConversation('synclist', item.channel_sid, item));
          });

          while (paginator.hasNextPage) {
            paginator = await paginator.nextPage();
            paginator.items.forEach(item => {
              upserts.push(this.upsertConversation('synclist', item.channel_sid, item));
            });
          }

          this.syncListRead.set(true);

          return Promise.all(upserts);
        })
        .then(() => {
          this.syncListFetched = true;
          this.thumbstones.clear();
          log.debug('Conversations list fetched');
        })
        .then(() => this)
        .catch(e => {
          if (this.services.syncClient.connectionState != 'disconnected') {
            log.error('Failed to get conversations list', e);
          }
          log.debug('ERROR: Failed to get conversations list', e);
          throw e;
        });
  }

  private _wrapPaginator(page, op) {
    return op(page.items)
      .then(items => ({
        items: items,
        hasNextPage: page.hasNextPage,
        hasPrevPage: page.hasPrevPage,
        nextPage: () => page.nextPage().then(x => this._wrapPaginator(x, op)),
        prevPage: () => page.prevPage().then(x => this._wrapPaginator(x, op))
      }));
  }

  getConversations(args) {
    return this.getMap()
               .then(conversationsMap => conversationsMap.getItems(args))
               .then(page => this._wrapPaginator(page
                 , items => Promise.all(items.map(item => this.upsertConversation('sync', item.key, item.data))))
               );
  }

  getConversation(sid: string): Promise<Conversation> {
    return this.getMap()
      .then(conversationsMap => conversationsMap.getItems({ key: sid }))
      .then(page => page.items.map(item => this.upsertConversation('sync', item.key, item.data)))
      .then(items => items.length > 0 ? items[0] : null);
  }

  async getConversationByUniqueName(uniqueName: string): Promise<Conversation> {
    const links = await this.services.session.getSessionLinks();
    const url = new UriBuilder(links.myChannelsUrl).path(uniqueName).build();
    const response = await this.services.network.get(url);
    const body = response.body;

    const sid = body.channel_sid;
    const data = {
      entityName: null,
      lastConsumedMessageIndex: body.last_consumed_message_index,
      status: body?.status || 'unknown',
      friendlyName: body.friendly_name,
      dateUpdated: body.date_updated,
      dateCreated: body.date_created,
      uniqueName: body.unique_name,
      createdBy: body.created_by,
      attributes: body.attributes,
      channel: `${sid}.channel`,
      notificationLevel: body?.notification_level,
      sid
    };

    return this.upsertConversation('sync', sid, data);
  }

  async getWhisperConversation(sid: string): Promise<Conversation> {
    const links = await this.services.session.getSessionLinks();
    const url = new UriBuilder(links.publicChannelsUrl).path(sid).build();
    const response = await this.services.network.get(url);
    const body = response.body;

    if (body.type !== 'private') {
      return;
    }

    // todo: refactor this after the back-end change.

    // Currently, a conversation that is created using a non-conversations-specific
    // endpoint (i.e., a chat-specific endpoint) will not have a state property set.
    // The back-end team will fix this, but only when they get some more time to work
    // on this. For now, the SDK will assume that the default state is active when
    // the property is absent from the REST response. The back-end team also mentioned
    // that the state property will become a proper JSON object, as opposed to a JSON
    // string, which is also covered in the following code.

    let state: {
      'state.v1'?: {
        current?: 'active' | 'inactive' | 'closed';
      }
    } | undefined;

    // If the state property is a string, it's expected to be a string that represents
    // a JSON object.

    if (typeof body.state === 'string') {
      state = JSON.parse(body.state);
    }

    // If the state property is already a non-nullable object, then no JSON parsing is
    // required.

    if (typeof body.state === 'object' && body.state !== null) {
      state = body.state;
    }

    if (state?.['state.v1']?.current === 'closed') {
      return;
    }

    const data = {
      entityName: null,
      lastConsumedMessageIndex: body.last_consumed_message_index,
      status: body?.status || 'unknown',
      friendlyName: body.friendly_name,
      dateUpdated: body.date_updated,
      dateCreated: body.date_created,
      uniqueName: body.unique_name,
      createdBy: body.created_by,
      attributes: body.attributes,
      channel: `${sid}.channel`,
      notificationLevel: body?.notification_level,
      sid
    };

    return this.upsertConversation('sync', sid, data);
  }

  private upsertConversation(source: Conversations.DataSource, sid: string, data): Promise<Conversation> {
    log.trace('upsertConversation(sid=' + sid + ', data=', data);
    let conversation = this.conversations.get(sid);

    // Update the Conversation's status if we know about it
    if (conversation) {
      log.trace('upsertConversation: conversation ' + sid + ' is known and it\'s' +
        ' status is known from source ' + conversation._statusSource() +
        ' and update came from source ' + source, conversation);
      if (typeof conversation._statusSource() === 'undefined'
        || source === conversation._statusSource()
        || (source === 'synclist' && conversation._statusSource() !== 'sync')
        || source === 'sync') {
        if (data.status === 'joined' && conversation.status !== 'joined') {
          conversation._setStatus('joined', source);

          let updateData: any = {};

          if (typeof data.notificationLevel !== 'undefined') {
            updateData.notificationLevel = data.notificationLevel;
          }

          if (typeof data.lastConsumedMessageIndex !== 'undefined') {
            updateData.lastConsumedMessageIndex = data.lastConsumedMessageIndex;
          }

          if (!isDeepEqual(updateData, {})) {
            conversation._update(updateData);
          }

          conversation._subscribe().then(() => { this.emit('conversationJoined', conversation); });
        } else if (data.status === 'notParticipating' && conversation.status === 'joined') {
          conversation._setStatus('notParticipating', source);
          conversation._update(data);
          conversation._subscribe().then(() => { this.emit('conversationLeft', conversation); });
        } else if (data.status === 'notParticipating') {
          conversation._subscribe();
        } else {
          conversation._update(data);
        }
      } else {
        log.trace('upsertConversation: conversation is known from sync and came from chat, ignoring', {
          sid: sid,
          data: data.status,
          conversation: conversation.status
        });

      }
      return conversation._subscribe().then(() => conversation);
    }

    if ((source === 'chat' || source === 'synclist') && this.thumbstones.has(sid)) {
      // if conversation was deleted, we ignore it
      log.trace('upsertConversation: conversation is deleted and came again from chat, ignoring', sid);
      return;
    }

    // Fetch the Conversation if we don't know about it
    log.trace('upsertConversation: creating local conversation object with sid ' + sid, data);
    conversation = new Conversation(this.services, data, sid);
    this.conversations.set(sid, conversation);
    return conversation._subscribe().then(() => {
      this.registerForEvents(conversation);
      this.emit('conversationAdded', conversation);
      if (data.status === 'joined') {
        conversation._setStatus('joined', source);
        this.emit('conversationJoined', conversation);
      }
      return conversation;
    });
  }

  private onConversationRemoved(sid: string) {
    let conversation = this.conversations.get(sid);
    if (conversation) {
      this.conversations.delete(sid);
      this.emit('conversationRemoved', conversation);
    }
  }

  private registerForEvents(conversation) {
    conversation.on('removed', () => this.onConversationRemoved(conversation.sid));
    conversation.on('updated', (args: Conversation.UpdatedEventArgs) => this.emit('conversationUpdated', args));
    conversation.on('participantJoined', this.emit.bind(this, 'participantJoined'));
    conversation.on('participantLeft', this.emit.bind(this, 'participantLeft'));
    conversation.on('participantUpdated', (args: Participant.UpdatedEventArgs) => this.emit('participantUpdated', args));
    conversation.on('messageAdded', this.emit.bind(this, 'messageAdded'));
    conversation.on('messageUpdated', (args: Message.UpdatedEventArgs) => this.emit('messageUpdated', args));
    conversation.on('messageRemoved', this.emit.bind(this, 'messageRemoved'));
    conversation.on('typingStarted', this.emit.bind(this, 'typingStarted'));
    conversation.on('typingEnded', this.emit.bind(this, 'typingEnded'));
  }
}

namespace Conversations {
  export type DataSource = 'sync' | 'chat' | 'synclist';
}

export { Conversation, Conversations };
