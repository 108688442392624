import { EventEmitter } from 'events';
import { Logger } from '../logger';

import { Message } from '../message';
import { Conversation } from '../conversation';

import { SyncList, SyncClient } from 'twilio-sync';
import { SyncPaginator } from '../syncpaginator';

import { Session } from '../session';
import { McsClient, McsMedia } from 'twilio-mcs-client';
import { Network } from '../services/network';

const log = Logger.scope('Messages');

export interface MessagesServices {
  session: Session;
  mcsClient: McsClient;
  network: Network;
  syncClient: SyncClient;
}

/**
 * Represents the collection of messages in a conversation
 */
class Messages extends EventEmitter {
  private readonly services: MessagesServices;
  private readonly messagesByIndex: Map<number, Message>;
  private messagesListPromise: Promise<SyncList>;

  public readonly conversation: Conversation;

  constructor(conversation: Conversation, services: MessagesServices) {
    super();

    this.conversation = conversation;
    this.services = services;

    this.messagesByIndex = new Map();
    this.messagesListPromise = null;
  }

  /**
   * Subscribe to the Messages Event Stream
   * @param {String} name - The name of Sync object for the Messages resource.
   * @returns {Promise}
   */
  subscribe(name: string) {
    return this.messagesListPromise =
      this.messagesListPromise ||
      this.services.syncClient.list({ id: name, mode: 'open_existing' })
          .then(list => {

            list.on('itemAdded', args => {
              log.debug(this.conversation.sid + ' itemAdded: ' + args.item.index);
              let message = new Message(this.conversation, this.services, args.item.index, args.item.data);
              if (this.messagesByIndex.has(message.index)) {
                log.debug('Message arrived, but already known and ignored', this.conversation.sid, message.index);
                return;
              }

              this.messagesByIndex.set(message.index, message);
              message.on('updated',
                (args: Message.UpdatedEventArgs) => this.emit('messageUpdated', args));
              this.emit('messageAdded', message);
            });

            list.on('itemRemoved', args => {
              log.debug(this.conversation.sid + ' itemRemoved: ' + args.index);
              let index = args.index;
              if (this.messagesByIndex.has(index)) {
                let message = this.messagesByIndex.get(index);
                this.messagesByIndex.delete(message.index);
                message.removeAllListeners('updated');
                this.emit('messageRemoved', message);
              }
            });

            list.on('itemUpdated', args => {
              log.debug(this.conversation.sid + ' itemUpdated: ' + args.item.index);
              let message = this.messagesByIndex.get(args.item.index);
              if (message) {
                message._update(args.item.data);
              }
            });

            return list;
          })
          .catch(err => {
            this.messagesListPromise = null;
            if (this.services.syncClient.connectionState != 'disconnected') {
              log.error('Failed to get messages object for conversation', this.conversation.sid, err);
            }
            log.debug('ERROR: Failed to get messages object for conversation', this.conversation.sid, err);
            throw err;
          });
  }

  async unsubscribe() {
    if (this.messagesListPromise) {
      let entity = await this.messagesListPromise;
      entity.close();
      this.messagesListPromise = null;
    }
  }

  /**
   * Send Message to the conversation
   * @param {String} message - Message to post
   * @param {any} attributes Message attributes
   * @param {Conversation.SendEmailOptions} emailOptions Options that modify E-mail integration behaviors.
   * @returns Returns promise which can fail
   */
  async send(message: string, attributes: any = {}, emailOptions?: Conversation.SendEmailOptions) {
    log.debug('Sending text message', message, attributes, emailOptions);

    return this.services.session.addCommand('sendMessage', {
      channelSid: this.conversation.sid,
      text: message,
      attributes: JSON.stringify(attributes),
      subject: emailOptions?.subject,
    });
  }

  /**
   * Send Media Message to the conversation
   * @param {FormData | Conversation#SendMediaOptions} mediaContent - Media content to post
   * @param {any} attributes Message attributes
   * @returns Returns promise which can fail
   */
  async sendMedia(mediaContent: FormData | Conversation.SendMediaOptions, attributes: any = {}, emailOptions?: Conversation.SendEmailOptions) {
    log.debug('Sending media message', mediaContent, attributes, emailOptions);

    let media: McsMedia;
    if (typeof FormData !== 'undefined'  && (mediaContent instanceof FormData)) {
      log.debug('Sending media message as FormData', mediaContent, attributes);
      media = await this.services.mcsClient.postFormData(mediaContent);
    } else {
      log.debug('Sending media message as SendMediaOptions', mediaContent, attributes);
      let mediaOptions = mediaContent as Conversation.SendMediaOptions;
      if (!mediaOptions.contentType || !mediaOptions.media) {
        throw new Error('Media content <Conversation#SendMediaOptions> must contain non-empty contentType and media');
      }
      media = await this.services.mcsClient.post(mediaOptions.contentType, mediaOptions.media);
    }
    // emailOptions are currently ignored for media messages.
    return this.services.session.addCommand('sendMediaMessage', {
      channelSid: this.conversation.sid,
      mediaSid: media.sid,
      attributes: JSON.stringify(attributes)
    });
  }

  /**
   * Returns messages from conversation using paginator interface
   * @param {Number} [pageSize] Number of messages to return in single chunk. By default it's 30.
   * @param {String} [anchor] Most early message id which is already known, or 'end' by default
   * @param {String} [direction] Pagination order 'backwards' or 'forward', or 'forward' by default
   * @returns {Promise<Paginator<Message>>} last page of messages by default
   */
  getMessages(pageSize, anchor, direction) {
    anchor = (typeof anchor !== 'undefined') ? anchor : 'end';
    direction = direction || 'backwards';
    return this._getMessages(pageSize, anchor, direction);
  }

  private wrapPaginator(order, page, op) {
    // We should swap next and prev page here, because of misfit of Sync and Chat paging conceptions
    let shouldReverse = order === 'desc';

    let np = () => page.nextPage().then(x => this.wrapPaginator(order, x, op));
    let pp = () => page.prevPage().then(x => this.wrapPaginator(order, x, op));

    return op(page.items).then(items => ({
      items: items.sort((x, y) => { return x.index - y.index; }),
      hasPrevPage: shouldReverse ? page.hasNextPage : page.hasPrevPage,
      hasNextPage: shouldReverse ? page.hasPrevPage : page.hasNextPage,
      prevPage: shouldReverse ? np : pp,
      nextPage: shouldReverse ? pp : np
    }));
  }

  private _upsertMessage(index: number, value: string) {
    let cachedMessage = this.messagesByIndex.get(index);
    if (cachedMessage) {
      return cachedMessage;
    }

    let message = new Message(this.conversation, this.services, index, value);
    this.messagesByIndex.set(message.index, message);
    message.on('updated',
      (args: Message.UpdatedEventArgs) => this.emit('messageUpdated', args));
    return message;
  }

  /**
   * Returns last messages from conversation
   * @param {Number} [pageSize] Number of messages to return in single chunk. By default it's 30.
   * @param {String} [anchor] Most early message id which is already known, or 'end' by default
   * @param {String} [direction] Pagination order 'backwards' or 'forward', or 'forward' by default
   * @returns {Promise<SyncPaginator<Message>>} last page of messages by default
   * @private
   */
  private _getMessages(pageSize, anchor, direction): Promise<SyncPaginator<Message>> {
    anchor = (typeof anchor !== 'undefined') ? anchor : 'end';
    pageSize = pageSize || 30;
    let order = direction === 'backwards' ? 'desc' : 'asc';

    return this.messagesListPromise
               .then(messagesList => messagesList.getItems({
                 from: anchor !== 'end' ? anchor : void (0),
                 pageSize,
                 order
               }))
               .then(page => this.wrapPaginator(order, page
                 , items => Promise.all(items.map(item => this._upsertMessage(item.index, item.data))))
               );
  }
}

export { Messages };
