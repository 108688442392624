import { EventEmitter } from 'events';
import { Logger } from './logger';

import { Participants } from './data/participants';
import { Participant } from './participant';
import { Messages } from './data/messages';
import { Message } from './message';

import { UriBuilder, isDeepEqual, parseToNumber } from './util';
import { Users } from './data/users';
import { Paginator } from './interfaces/paginator';
import { Conversations } from './data/conversations';
import { McsClient } from 'twilio-mcs-client';

import { SyncClient } from 'twilio-sync';
import { Session } from './session';
import { ReadHorizon } from './services/readhorizon';
import { TypingIndicator } from './services/typingindicator';
import { Network } from './services/network';
import { validateTypesAsync, custom, literal, nonEmptyString, nonNegativeInteger, objectSchema } from 'twilio-sdk-type-validator';

const log = Logger.scope('Conversation');

const fieldMappings = {
  lastMessage: 'lastMessage',
  attributes: 'attributes',
  createdBy: 'createdBy',
  dateCreated: 'dateCreated',
  dateUpdated: 'dateUpdated',
  friendlyName: 'friendlyName',
  lastConsumedMessageIndex: 'lastConsumedMessageIndex',
  notificationLevel: 'notificationLevel',
  sid: 'sid',
  status: 'status',
  uniqueName: 'uniqueName',
  state: 'state'
};

function parseTime(timeString) {
  try {
    return new Date(timeString);
  } catch (e) {
    return null;
  }
}

export interface ConversationServices {
  session: Session;
  users: Users;
  typingIndicator: TypingIndicator;
  readHorizon: ReadHorizon;
  network: Network;
  mcsClient: McsClient;
  syncClient: SyncClient;
}

interface ConversationState {
  uniqueName: string;
  status: Conversation.Status;
  attributes: any;
  createdBy?: string;
  dateCreated: Date;
  dateUpdated: Date;
  friendlyName?: string;
  lastReadMessageIndex: number | null;
  lastMessage?: Conversation.LastMessage;
  notificationLevel?: Conversation.NotificationLevel;
  state?: Conversation.State;
}

interface ConversationDescriptor {
  channel: string;
  entityName: string;
  uniqueName: string;
  attributes: any;
  createdBy?: string;
  friendlyName?: string;
  lastConsumedMessageIndex: number;
  dateCreated: any;
  dateUpdated: any;
  notificationLevel?: Conversation.NotificationLevel;
}

namespace Conversation {
  export type UpdateReason = 'attributes' | 'createdBy' | 'dateCreated' | 'dateUpdated' |
    'friendlyName' | 'lastReadMessageIndex' | 'state' | 'status' | 'uniqueName' | 'lastMessage' | 'notificationLevel';

  export type Status = 'notParticipating' | 'joined';

  export type NotificationLevel = 'default' | 'muted';

  export type State = {
    current: 'active' | 'inactive' | 'closed',
    dateUpdated: Date
  } | undefined;

  export interface UpdatedEventArgs {
    conversation: Conversation;
    updateReasons: Conversation.UpdateReason[];
  }

  export interface SendMediaOptions {
    contentType: string;
    media: string | Buffer;
  }

  export interface SendEmailOptions {
    subject?: string;
  }

  export interface LastMessage {
    index?: number;
    dateCreated?: Date;
  }
}

/**
 * @classdesc A Conversation represents communication between multiple Conversations Clients
 * @property {any} attributes - The Conversation's custom attributes
 * @property {String} createdBy - The identity of the User that created this Conversation
 * @property {Date} dateCreated - The Date this Conversation was created
 * @property {Date} dateUpdated - The Date this Conversation was last updated
 * @property {String} [friendlyName] - The Conversation's name
 * @property {Number|null} lastReadMessageIndex - Index of the last Message the User has read in this Conversation
 * @property {Conversation#LastMessage} lastMessage - Last Message sent to this Conversation
 * @property {Conversation#NotificationLevel} notificationLevel - User Notification level for this Conversation
 * @property {String} sid - The Conversation's unique system identifier
 * @property {Conversation#Status} status - The Conversation's status
 * @property {Conversation#State} state - The Conversation's state
 * @property {String} uniqueName - The Conversation's unique name
 * @fires Conversation#participantJoined
 * @fires Conversation#participantLeft
 * @fires Conversation#participantUpdated
 * @fires Conversation#messageAdded
 * @fires Conversation#messageRemoved
 * @fires Conversation#messageUpdated
 * @fires Conversation#typingEnded
 * @fires Conversation#typingStarted
 * @fires Conversation#updated
 * @fires Conversation#removed
 */
class Conversation extends EventEmitter {
  public readonly sid: string;

  private services: ConversationServices;
  private channelState: ConversationState;
  private statusSource: Conversations.DataSource;

  private entityPromise: Promise<any>;
  private entityName: string;
  private entity: any;
  private messagesEntity: any;
  private participantsEntity: Participants;
  private participants: any;

  /**
   * These options can be passed to {@link Conversation#sendMessage}.
   * @typedef {Object} Conversation#SendMediaOptions
   * @property {String} contentType - content type of media
   * @property {String | Buffer} media - content to post
   */

  /**
   * These options can be passed to {@link Conversation#sendMessage}.
   * @typedef {Object} Conversation#SendEmailOptions
   * @property {String} subject - subject for the message. Ignored for media messages.
   */

  /**
   * The update reason for <code>updated</code> event emitted on Conversation
   * @typedef {('attributes' | 'createdBy' | 'dateCreated' | 'dateUpdated' |
    'friendlyName' | 'lastReadMessageIndex' | 'state' | 'status' | 'uniqueName' | 'lastMessage' |
    'notificationLevel' )} Conversation#UpdateReason
   */

  /**
   * The status of the Conversation, relative to the Client: whether the Conversation has been <code>joined</code> or the Client is
   * <code>notParticipating</code> in the Conversation.
   * @typedef {('notParticipating' | 'joined')} Conversation#Status
   */

  /**
   * The User's Notification level for Conversation, determines whether the currently logged-in User will receive
   * pushes for events in this Conversation. Can be either <code>muted</code> or <code>default</code>,
   * where <code>default</code> defers to global Service push configuration.
   * @typedef {('default' | 'muted')} Conversation#NotificationLevel
   */

  /**
   * The Conversation's state.
   * @typedef {Object} Conversation#State
   * @property {('active' | 'inactive' | 'closed')} current - the current state
   * @property {Date} dateUpdated - date at which the latest conversation state update happened
   */

  constructor(services: ConversationServices, descriptor: ConversationDescriptor, sid: string) {
    super();

    let attributes = descriptor.attributes || {};
    let createdBy = descriptor.createdBy;
    let dateCreated = parseTime(descriptor.dateCreated);
    let dateUpdated = parseTime(descriptor.dateUpdated);
    let friendlyName = descriptor.friendlyName || null;
    let lastReadMessageIndex =
      Number.isInteger(descriptor.lastConsumedMessageIndex) ? descriptor.lastConsumedMessageIndex : null;
    let uniqueName = descriptor.uniqueName || null;

    try {
      JSON.stringify(attributes);
    } catch (e) {
      throw new Error('Attributes must be a valid JSON object.');
    }

    this.services = services;

    this.sid = sid;
    this.entityName = descriptor.channel;
    this.channelState = {
      uniqueName,
      status: 'notParticipating',
      attributes,
      createdBy,
      dateCreated,
      dateUpdated,
      friendlyName,
      lastReadMessageIndex: lastReadMessageIndex
    };

    if (descriptor.notificationLevel) {
      this.channelState.notificationLevel = descriptor.notificationLevel;
    }

    this.participants = new Map();
    this.participantsEntity = new Participants(this, this.services, this.participants);
    this.participantsEntity.on('participantJoined', this.emit.bind(this, 'participantJoined'));
    this.participantsEntity.on('participantLeft', this.emit.bind(this, 'participantLeft'));
    this.participantsEntity.on('participantUpdated',
      (args: Participant.UpdatedEventArgs) => this.emit('participantUpdated', args));

    this.messagesEntity = new Messages(this, services);
    this.messagesEntity.on('messageAdded', message => this._onMessageAdded(message));
    this.messagesEntity.on('messageUpdated',
      (args: Message.UpdatedEventArgs) => this.emit('messageUpdated', args));
    this.messagesEntity.on('messageRemoved', this.emit.bind(this, 'messageRemoved'));
  }

  public get uniqueName(): string { return this.channelState.uniqueName; }

  public get status(): Conversation.Status { return this.channelState.status; }

  public get friendlyName(): string { return this.channelState.friendlyName; }

  public get dateUpdated(): any { return this.channelState.dateUpdated; }

  public get dateCreated(): any { return this.channelState.dateCreated; }

  public get createdBy(): string { return this.channelState.createdBy; }

  public get attributes(): Object { return this.channelState.attributes; }

  public get lastReadMessageIndex(): number | null { return this.channelState.lastReadMessageIndex; }

  public get lastMessage(): Conversation.LastMessage { return this.channelState.lastMessage; }

  public get notificationLevel(): Conversation.NotificationLevel { return this.channelState.notificationLevel; }

  public get state(): Conversation.State { return this.channelState.state; }

  /**
   * The Conversation's last message's information.
   * @typedef {Object} Conversation#LastMessage
   * @property {Number} index - Message's index
   * @property {Date} dateCreated - Message's creation date
   */

  /**
   * Load and Subscribe to this Conversation and do not subscribe to its Participants and Messages.
   * This or _subscribeStreams will need to be called before any events on Conversation will fire.
   * @returns {Promise}
   * @private
   */
  _subscribe() {
    if (this.entityPromise) { return this.entityPromise; }

    return this.entityPromise = this.entityPromise ||
      this.services.syncClient.document({ id: this.entityName, mode: 'open_existing' })
        .then(entity => {
          this.entity = entity;
          this.entity.on('updated', args => { this._update(args.data); });
          this.entity.on('removed', () => this.emit('removed', this));
          this._update(this.entity.data);
          return entity;
        })
        .catch(err => {
          this.entity = null;
          this.entityPromise = null;
          if (this.services.syncClient.connectionState != 'disconnected') {
            log.error('Failed to get conversation object', err);
          }
          log.debug('ERROR: Failed to get conversation object', err);
          throw err;
        });
  }

  /**
   * Load the attributes of this Conversation and instantiate its Participants and Messages.
   * This or _subscribe will need to be called before any events on Conversation will fire.
   * This will need to be called before any events on Participants or Messages will fire
   * @returns {Promise}
   * @private
   */
  async _subscribeStreams() {
    try {
      await this._subscribe();
      log.trace('_subscribeStreams, this.entity.data=', this.entity.data);
      const messagesObjectName = this.entity.data.messages;
      const rosterObjectName = this.entity.data.roster;
      await Promise.all([
        this.messagesEntity.subscribe(messagesObjectName),
        this.participantsEntity.subscribe(rosterObjectName)
      ]);
    } catch (err) {
      if (this.services.syncClient.connectionState !== 'disconnected') {
        log.error('Failed to subscribe on conversation objects', this.sid, err);
      }
      log.debug('ERROR: Failed to subscribe on conversation objects', this.sid, err);
      throw err;
    }
  }

  /**
   * Stop listening for and firing events on this Conversation.
   * @returns {Promise}
   * @private
   */
  async _unsubscribe() {
    if (this.entity) {
      await this.entity.close();
      this.entity = null;
      this.entityPromise = null;
    }

    return Promise.all([
      this.participantsEntity.unsubscribe(),
      this.messagesEntity.unsubscribe()
    ]);
  }

  /**
   * Set conversation status
   * @private
   */
  _setStatus(status: Conversation.Status, source: Conversations.DataSource) {
    this.statusSource = source;

    if (this.channelState.status === status) { return; }

    this.channelState.status = status;

    if (status === 'joined') {
      this._subscribeStreams()
        .catch(err => {
          log.debug('ERROR while setting conversation status ' + status, err);
          if (this.services.syncClient.connectionState !== 'disconnected') {
            throw err;
          }
        });
    } else if (this.entityPromise) {
      this._unsubscribe().catch(err => {
        log.debug('ERROR while setting conversation status ' + status, err);
        if (this.services.syncClient.connectionState !== 'disconnected') {
          throw err;
        }
      });
    }
  }

  /**
   * If conversation's status update source
   * @private
   * @return {Conversations.DataSource}
   */
  _statusSource(): Conversations.DataSource {
    return this.statusSource;
  }

  private static preprocessUpdate(update, conversationSid) {
    try {
      if (typeof update.attributes === 'string') {
        update.attributes = JSON.parse(update.attributes);
      } else if (update.attributes) {
        JSON.stringify(update.attributes);
      }
    } catch (e) {
      log.warn('Retrieved malformed attributes from the server for conversation: ' + conversationSid);
      update.attributes = {};
    }

    try {
      if (update.dateCreated) {
        update.dateCreated = new Date(update.dateCreated);
      }
    } catch (e) {
      log.warn('Retrieved malformed dateCreated from the server for conversation: ' + conversationSid);
      delete update.dateCreated;
    }

    try {
      if (update.dateUpdated) {
        update.dateUpdated = new Date(update.dateUpdated);
      }
    } catch (e) {
      log.warn('Retrieved malformed dateUpdated from the server for conversation: ' + conversationSid);
      delete update.dateUpdated;
    }

    try {
      if (update.lastMessage && update.lastMessage.timestamp) {
        update.lastMessage.timestamp = new Date(update.lastMessage.timestamp);
      }
    } catch (e) {
      log.warn('Retrieved malformed lastMessage.timestamp from the server for conversation: ' + conversationSid);
      delete update.lastMessage.timestamp;
    }
  }

  /**
   * Updates local conversation object with new values
   * @private
   */
  _update(update) {
    log.trace('_update', update);

    Conversation.preprocessUpdate(update, this.sid);
    const updateReasons = new Set<Conversation.UpdateReason>();

    for (const key of Object.keys(update)) {
      const localKey = fieldMappings[key];

      if (!localKey) {
        continue;
      }

      switch (localKey) {
        case fieldMappings.status:
          if (!update.status || update.status === 'unknown'
            || this.channelState.status === update.status) {
            break;
          }

          this.channelState.status = update.status;
          updateReasons.add(localKey);

          break;
        case fieldMappings.attributes:
          if (isDeepEqual(this.channelState.attributes, update.attributes)) {
            break;
          }

          this.channelState.attributes = update.attributes;
          updateReasons.add(localKey);

          break;
        case fieldMappings.lastConsumedMessageIndex:
          if (update.lastConsumedMessageIndex === undefined
            || update.lastConsumedMessageIndex === this.channelState.lastReadMessageIndex) {
            break;
          }

          this.channelState.lastReadMessageIndex = update.lastConsumedMessageIndex;
          updateReasons.add('lastReadMessageIndex');

          break;
        case fieldMappings.lastMessage:
          if (this.channelState.lastMessage && !update.lastMessage) {
            delete this.channelState.lastMessage;
            updateReasons.add(localKey);

            break;
          }

          this.channelState.lastMessage = this.channelState.lastMessage || {};

          if (update.lastMessage?.index !== undefined
            && update.lastMessage.index !== this.channelState.lastMessage.index) {
            this.channelState.lastMessage.index = update.lastMessage.index;
            updateReasons.add(localKey);
          }

          if (update.lastMessage?.timestamp !== undefined
            && this.channelState.lastMessage?.dateCreated?.getTime() !== update.lastMessage.timestamp.getTime()) {
            this.channelState.lastMessage.dateCreated = update.lastMessage.timestamp;
            updateReasons.add(localKey);
          }

          if (isDeepEqual(this.channelState.lastMessage, {})) {
            delete this.channelState.lastMessage;
          }

          break;
        case fieldMappings.state:
          const state = update.state || undefined;

          if (state !== undefined) {
            state.dateUpdated = new Date(state.dateUpdated);
          }

          if (isDeepEqual(this.channelState.state, state)) {
            break;
          }

          this.channelState.state = state;
          updateReasons.add(localKey);

          break;
        default:
          const isDate = update[key] instanceof Date;
          const keysMatchAsDates = isDate && this.channelState[localKey]?.getTime() === update[key].getTime();
          const keysMatchAsNonDates = !isDate && this[localKey] === update[key];

          if (keysMatchAsDates || keysMatchAsNonDates) {
            break;
          }

          this.channelState[localKey] = update[key];
          updateReasons.add(localKey);
      }
    }

    if (updateReasons.size > 0) {
      this.emit('updated', { conversation: this, updateReasons: [...updateReasons] });
    }
  }

  /**
   * @private
   */
  private _onMessageAdded(message) {
    for (let participant of this.participants.values()) {
      if (participant.identity === message.author) {
        participant._endTyping();
        break;
      }
    }
    this.emit('messageAdded', message);
  }

  /**
   * Add a participant to the Conversation by its Identity.
   * @param {String} identity - Identity of the Client to add
   * @param {any} [attributes] Attributes to be attached to the participant
   * @returns {Promise<void>}
   */
  @validateTypesAsync(
    nonEmptyString,
    ['undefined', 'string', 'number', 'boolean', 'object', literal(null)]
  )
  async add(identity: string, attributes?: any): Promise<void> {
    return this.participantsEntity.add(identity, attributes);
  }

  /**
   * Add a non-chat participant to the Conversation.
   *
   * @param {String} proxyAddress Proxy (Twilio) address of the participant
   * @param {String} address User address of the participant
   * @param {any} [attributes] Attributes to be attached to the participant
   * @returns {Promise<void>}
   */
  @validateTypesAsync(nonEmptyString, nonEmptyString, ['undefined', 'object'])
  async addNonChatParticipant(proxyAddress: string, address: string, attributes: Record<string, any> = {}) {
    return this.participantsEntity.addNonChatParticipant(proxyAddress, address, attributes);
  }

  /**
   * Advance Conversation's last read Message index to current read horizon.
   * Rejects if User is not Participant of Conversation.
   * Last read Message index is updated only if new index value is higher than previous.
   * @param {Number} index - Message index to advance to as last read
   * @returns {Promise<number>} resulting unread messages count in the conversation
   */
  @validateTypesAsync(nonNegativeInteger)
  async advanceLastReadMessageIndex(index: number): Promise<number> {
    await this._subscribeStreams();
    return this.services.readHorizon.advanceLastReadMessageIndexForConversation(this.sid, index, this.lastReadMessageIndex);
  }

  /**
   * Delete the Conversation and unsubscribe from its events.
   * @returns {Promise<Conversation>}
   */
  async delete(): Promise<Conversation> {
    await this.services.session.addCommand('destroyChannel', {
      channelSid: this.sid
    });
    return this;
  }

  /**
   * Get the custom attributes of this Conversation.
   * @returns {Promise<any>} attributes of this Conversation
   */
  async getAttributes(): Promise<any> {
    await this._subscribe();
    return this.attributes;
  }

  /**
   * Returns messages from conversation using paginator interface.
   * @param {Number} [pageSize=30] Number of messages to return in single chunk
   * @param {Number} [anchor] - Index of newest Message to fetch. From the end by default
   * @param {('backwards'|'forward')} [direction=backwards] - Query direction. By default it query backwards
   *                                                          from newer to older. 'forward' will query in opposite direction
   * @returns {Promise<Paginator<Message>>} page of messages
   */
  @validateTypesAsync(
    ['undefined', nonNegativeInteger],
    ['undefined', nonNegativeInteger],
    ['undefined', literal('backwards', 'forward')]
  )
  async getMessages(pageSize?: number, anchor?: number, direction?: 'backwards' | 'forward'): Promise<Paginator<Message>> {
    await this._subscribeStreams();
    return this.messagesEntity.getMessages(pageSize, anchor, direction);
  }

  /**
   * Get a list of all Participants joined to this Conversation.
   * @returns {Promise<Participant[]>}
   */
  async getParticipants(): Promise<Participant[]> {
    await this._subscribeStreams();
    return this.participantsEntity.getParticipants();
  }

  /**
   * Get conversation participants count.
   * <br/>
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also possibly be incorrect for a few seconds. The Conversation system does not
   * provide real time events for counter values changes.
   * <br/>
   * So this is quite useful for any UI badges, but is not recommended
   * to build any core application logic based on these counters being accurate in real time.
   * @returns {Promise<number>}
   */
  async getParticipantsCount(): Promise<number> {
    let links = await this.services.session.getSessionLinks();
    let url = new UriBuilder(links.publicChannelsUrl).path(this.sid).build();
    let response = await this.services.network.get(url);
    return response.body.members_count;
  }

  /**
   * Get a Participant by its SID.
   * @param {String} participantSid - Participant sid
   * @returns {Promise<Participant>}
   */
  @validateTypesAsync(nonEmptyString)
  async getParticipantBySid(participantSid: string): Promise<Participant> {
    return this.participantsEntity.getParticipantBySid(participantSid);
  }

  /**
   * Get a Participant by its identity.
   * @param {String} identity - Participant identity
   * @returns {Promise<Participant>}
   */
  @validateTypesAsync(nonEmptyString)
  async getParticipantByIdentity(identity: string): Promise<Participant> {
    return this.participantsEntity.getParticipantByIdentity(identity);
  }

  /**
   * Get total message count in a conversation.
   * <br/>
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also possibly be incorrect for a few seconds. The Conversations system does not
   * provide real time events for counter values changes.
   * <br/>
   * So this is quite useful for any UI badges, but is not recommended
   * to build any core application logic based on these counters being accurate in real time.
   * @returns {Promise<number>}
   */
  async getMessagesCount(): Promise<number> {
    let links = await this.services.session.getSessionLinks();
    let url = new UriBuilder(links.publicChannelsUrl).path(this.sid).build();
    let response = await this.services.network.get(url);
    return response.body.messages_count;
  }

  /**
   * Get unread messages count for the User if they are a Participant of this Conversation.
   * Rejects if the User is not a Participant of the Conversation.
   * <br/>
   * Use this method to obtain the number of unread messages together with
   * updateLastReadMessageIndex() instead of relying on the
   * Message indices which may have gaps. See Message.index for details.
   * <br/>
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also possibly be incorrect for a few seconds. The Chat system does not
   * provide real time events for counter values changes.
   * <br/>
   * This is quite useful for any “unread messages count” badges, but is not recommended
   * to build any core application logic based on these counters being accurate in real time.
   * @returns {Promise<number|null>}
   */
  async getUnreadMessagesCount(): Promise<number | null> {
    let links = await this.services.session.getSessionLinks();
    let url = new UriBuilder(links.myChannelsUrl).arg('ChannelSid', this.sid).build();
    let response = await this.services.network.get(url);
    if (response.body.channels.length && response.body.channels[0].channel_sid == this.sid) {
      if ((typeof response.body.channels[0].unread_messages_count !== 'undefined') && response.body.channels[0].unread_messages_count != null) {
        return response.body.channels[0].unread_messages_count;
      }
      return null;
    }

    throw new Error('Conversation is not in user conversations list');
  }

  /**
   * Join the Conversation and subscribe to its events.
   * @returns {Promise<Conversation>}
   */
  async join(): Promise<Conversation> {
    await this.services.session.addCommand('joinChannelV2', { channelSid: this.sid });
    return this;
  }

  /**
   * Leave the Conversation.
   * @returns {Promise<Conversation>}
   */
  async leave(): Promise<Conversation> {
    if (this.channelState.status === 'joined') {
      await this.services.session.addCommand('leaveChannel', { channelSid: this.sid });
    }

    return this;
  }

  /**
   * Remove a Participant from the Conversation. When a string is passed as the argument, it will assume that the string is an identity.
   * @param {String|Participant} participant - identity or participant object to remove
   * @returns {Promise<void>}
   */
  @validateTypesAsync([nonEmptyString, Participant])
  async removeParticipant(participant: string | Participant): Promise<void> {
    if (participant instanceof Participant) {
      await this.participantsEntity.removeBySid(participant.sid);
      return;
    }

    await this.participantsEntity.removeByIdentity(participant as string);
  }

  /**
   * Send a Message in the Conversation.
   * @param {String|FormData|Conversation#SendMediaOptions|null} message - The message body for text message,
   * FormData or MediaOptions for media content. Sending FormData supported only with browser engine
   * @param {any} [messageAttributes] - attributes for the message
   * @param {Conversation#SendEmailOptions} [emailOptions] - email options for the message
   * @returns {Promise<number>} new Message's index in the Conversation's messages list
   */
  @validateTypesAsync(
    [
      'string',
      literal(null),
      // Wrapping it into a custom rule is necessary because the FormData class is not available on initialization.
      custom((value) => [value instanceof FormData, 'an instance of FormData']),
      objectSchema('media options', {
        contentType: nonEmptyString,
        media: custom((value) => {
          let isValid = (typeof value === 'string' && value.length > 0) || value instanceof Uint8Array || value instanceof ArrayBuffer;

          if (typeof Blob === 'function') {
            isValid = isValid || value instanceof Blob;
          }

          return [
            isValid,
            'a non-empty string, an instance of Buffer or an instance of Blob'
          ];
        })
      })
    ],
    ['undefined', 'string', 'number', 'boolean', 'object', literal(null)],
    ['undefined', literal(null), objectSchema('email attributes', {
      subject: [nonEmptyString, 'undefined']
    })]
  )
  async sendMessage(message: string | FormData | Conversation.SendMediaOptions | null, messageAttributes?: any,
                    emailOptions?: Conversation.SendEmailOptions): Promise<number> {
    if (typeof message === 'string' || message === null) {
      let response = await this.messagesEntity.send(message, messageAttributes, emailOptions);
      return parseToNumber(response.messageId);
    }

    let response = await this.messagesEntity.sendMedia(message, messageAttributes, emailOptions);
    return parseToNumber(response.messageId);
  }

  /**
   * Set last read Conversation's Message index to last known Message's index in this Conversation.
   * @returns {Promise<number>} resulting unread messages count in the conversation
   */
  async setAllMessagesRead(): Promise<number> {
    await this._subscribeStreams();

    let messagesPage = await this.getMessages(1);

    if (messagesPage.items.length > 0) {
      return this.advanceLastReadMessageIndex(messagesPage.items[0].index);
    }

    return Promise.resolve(0);
  }

  /**
   * Set all messages in the conversation unread.
   * @returns {Promise<number>} resulting unread messages count in the conversation
   */
  async setAllMessagesUnread(): Promise<number> {
    await this._subscribeStreams();
    return this.services.readHorizon.updateLastReadMessageIndexForConversation(this.sid, null);
  }

  /**
   * Set User Notification level for this conversation.
   * @param {Conversation#NotificationLevel} notificationLevel - The new user notification level
   * @returns {Promise<void>}
   */
  @validateTypesAsync(literal('default', 'muted'))
  async setUserNotificationLevel(notificationLevel: Conversation.NotificationLevel): Promise<void> {
    await this.services.session.addCommand('editNotificationLevel', { channelSid: this.sid, notificationLevel: notificationLevel });
  }

  /**
   * Send a notification to the server indicating that this Client is currently typing in this Conversation.
   * Typing ended notification is sent after a while automatically, but by calling again this method you ensure typing ended is not received.
   * @returns {Promise<void>}
   */
  typing(): Promise<void> {
    return this.services.typingIndicator.send(this.sid);
  }

  /**
   * Update the Conversation's attributes.
   * @param {any} attributes - The new attributes object
   * @returns {Promise<Conversation>}
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  async updateAttributes(attributes: any): Promise<Conversation> {
    await this.services.session.addCommand('editAttributes', {
      channelSid: this.sid,
      attributes: JSON.stringify(attributes)
    });

    return this;
  }

  /**
   * Update the Conversation's friendlyName.
   * @param {String|null} name - The new Conversation friendlyName
   * @returns {Promise<Conversation>}
   */
  @validateTypesAsync(['string', literal(null)])
  async updateFriendlyName(name: string | null): Promise<Conversation> {
    if (this.channelState.friendlyName !== name) {
      await this.services.session.addCommand('editFriendlyName', {
        channelSid: this.sid,
        friendlyName: name
      });
    }

    return this;
  }

  /**
   * Set Conversation's last read Message index to current read horizon.
   * @param {Number|null} index - Message index to set as last read.
   * If null provided, then the behavior is identical to {@link Conversation#setAllMessagesUnread}
   * @returns {Promise<number>} resulting unread messages count in the conversation
   */
  @validateTypesAsync([literal(null), nonNegativeInteger])
  async updateLastReadMessageIndex(index: number | null): Promise<number> {
    await this._subscribeStreams();
    return this.services.readHorizon.updateLastReadMessageIndexForConversation(this.sid, index);
  }

  /**
   * Update the Conversation's unique name.
   * @param {String|null} uniqueName - New unique name for the Conversation. Setting unique name to null removes it.
   * @returns {Promise<Conversation>}
   */
  @validateTypesAsync(['string', literal(null)])
  async updateUniqueName(uniqueName: string | null): Promise<Conversation> {
    if (this.channelState.uniqueName !== uniqueName) {
      if (!uniqueName) {
        uniqueName = '';
      }

      await this.services.session.addCommand('editUniqueName', {
        channelSid: this.sid,
        uniqueName: uniqueName
      });
    }

    return this;
  }
}

export { ConversationDescriptor, Conversation };

/**
 * Fired when a Participant has joined the Conversation.
 * @event Conversation#participantJoined
 * @type {Participant}
 */
/**
 * Fired when a Participant has left the Conversation.
 * @event Conversation#participantLeft
 * @type {Participant}
 */
/**
 * Fired when a Participant's fields has been updated.
 * @event Conversation#participantUpdated
 * @type {Object}
 * @property {Participant} participant - Updated Participant
 * @property {Participant#UpdateReason[]} updateReasons - Array of Participant's updated event reasons
 */
/**
 * Fired when a new Message has been added to the Conversation.
 * @event Conversation#messageAdded
 * @type {Message}
 */
/**
 * Fired when Message is removed from Conversation's message list.
 * @event Conversation#messageRemoved
 * @type {Message}
 */
/**
 * Fired when an existing Message's fields are updated with new values.
 * @event Conversation#messageUpdated
 * @type {Object}
 * @property {Message} message - Updated Message
 * @property {Message#UpdateReason[]} updateReasons - Array of Message's updated event reasons
 */
/**
 * Fired when a Participant has stopped typing.
 * @event Conversation#typingEnded
 * @type {Participant}
 */
/**
 * Fired when a Participant has started typing.
 * @event Conversation#typingStarted
 * @type {Participant}
 */
/**
 * Fired when a Conversation's attributes or metadata have been updated.
 * @event Conversation#updated
 * @type {Object}
 * @property {Conversation} conversation - Updated Conversation
 * @property {Conversation#UpdateReason[]} updateReasons - Array of Conversation's updated event reasons
 */
/**
 * Fired when the Conversation was destroyed or currently logged in User has left private Conversation
 * @event Conversation#removed
 * @type {Conversation}
 */
