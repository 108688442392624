/**
 * Contains sync list item information.
 *
 * @property {string} channel_sid Conversation sid
 * @property {string} conversation Conversation entity name
 * @property {string} messages Conversation messages entity name
 * @property {string} roster Conversation roster entity name
 * @property {Number} lastReadMessageIndex Index of the last Message the User has read in this Conversation

 */
import { Conversation } from './conversation';

class SyncListDescriptor {

  public readonly channel_sid: string;
  public readonly status: string;
  public readonly channel: string;
  public readonly messages: string;
  public readonly roster: string;
  public readonly lastConsumedMessageIndex: number | null;
  public readonly notificationLevel: Conversation.NotificationLevel;
  public readonly descriptor: any;

  /**
   * @param {Object} descriptor - sync list descriptor data object
   * @private
   */
  constructor(descriptor) {
    this.channel_sid = descriptor.channel_sid;
    this.status = descriptor.status;
    this.channel = descriptor.channel;
    this.messages = descriptor.messages;
    this.roster = descriptor.roster;
    this.lastConsumedMessageIndex = descriptor.last_consumed_message_index;
    this.notificationLevel = descriptor.notification_level;
    this.descriptor = descriptor;
  }
}

export { SyncListDescriptor };
