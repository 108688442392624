import * as uuid from 'uuid';
import * as platform from 'platform';
import { ResponseCodes } from './interfaces/responsecodes';

import { Logger } from './logger';
import { Configuration } from './configuration';

import { SessionError } from './sessionerror';
import { Deferred } from './util/deferred';
import { SyncClient } from 'twilio-sync';
import { parse as parseDuration, toSeconds } from 'iso8601-duration';
import { version } from '../package.json';

const SDK_VERSION = version;
const SESSION_PURPOSE = 'com.twilio.rtd.ipmsg';

const log = Logger.scope('Session');

class Command {
  resolve: any;
  reject: any;
  commandId: any;
  request: any;
}

interface SessionLinks {
  publicChannelsUrl: string;
  myChannelsUrl: string;
  typingUrl: string;
  syncListUrl: string;
  usersUrl: string;
  mediaServiceUrl: string;
  messagesReceiptsUrl: string;
}

interface ImmutableSessionInfo {
  identity: string;
  links: SessionLinks;

  userInfo: string;
  channels: string;
  myChannels: string;
  userInfosToSubscribe: number;
}

export interface SessionServices {
  syncClient: SyncClient;
}

function hasAllPropertiesSet(obj: Object, properties: string[]): boolean {
  return !(properties.some(prop => !obj.hasOwnProperty(prop)));
}

/**
 *  Constructs the instance of Session
 *
 *  @classdesc Provides the interface to send the command to the server
 *  It is reliable, which means that it tracks the command object state
 *  and waits the answer from the server.
 */
class Session {
  public readonly services: SessionServices;

  private endpointPlatform: string;
  private config: any;

  private pendingCommands: Map<string, Command>;
  private sessionStreamPromise: any;

  private readonly sessionInfo: Deferred<ImmutableSessionInfo>;
  private currentContext: any;

  constructor(services: SessionServices, config: Configuration) {
    let platformInfo = typeof navigator !== 'undefined' ?
      platform.parse(navigator.userAgent) : platform;

    this.services = services;
    this.config = config;

    this.sessionInfo = new Deferred<ImmutableSessionInfo>();
    this.currentContext = {};

    this.pendingCommands = new Map();
    this.sessionStreamPromise = null;

    this.endpointPlatform = [
      'JS',
      SDK_VERSION,
      platformInfo.os,
      platformInfo.name,
      platformInfo.version
    ].join('|');
  }

  public get identity(): string { return this.sessionInfo.current.identity; }

  public get reachabilityEnabled(): boolean { return this.currentContext.reachabilityEnabled; }

  private handleContextUpdate(updatedContext: any) {
    log.info('Session context updated');
    log.debug('new session context:', updatedContext);

    this.currentContext = updatedContext;

    if (!hasAllPropertiesSet(updatedContext, ['identity', 'userInfo', 'links', 'myChannels', 'channels'])) {
      return; // not enough data to proceed, wait
    }

    log.info('new session context accepted');
    this.sessionInfo.set(updatedContext);
  }

  initialize(): Promise<Session> {
    let context = {
      type: 'IpMsgSession',
      apiVersion: '4',
      endpointPlatform: this.endpointPlatform
    };

    this.sessionStreamPromise = this.services.syncClient.list({ purpose: SESSION_PURPOSE, context })
                                    .then(list => {
                                      log.info('Session created', list.sid);
                                      list.on('itemAdded', args => this.processCommandResponse(args.item));
                                      list.on('itemUpdated', args => this.processCommandResponse(args.item));
                                      list.on('contextUpdated', args => this.handleContextUpdate(args.context));
                                      return list;
                                    }).catch(function(err) {
        log.error('Failed to create session', err);
        throw err;
      });

    return this.sessionStreamPromise;
  }

  /**
   * Sends the command to the server
   * @returns Promise the promise, which is being fulfilled only when service will reply
   */
  addCommand(action: string, params: any): Promise<any> {
    return this.processCommand(action, params);
  }

  /**
   * @private
   */
  private processCommand(action, params, createSessionIfNotFound = true) {
    let command = new Command();
    command.request = params;
    command.request.action = action;
    command.commandId = uuid.v4();

    log.info('Adding command: ', action, command.commandId);
    log.debug('command arguments:', params, createSessionIfNotFound);

    return new Promise((resolve, reject) => {
      this.sessionStreamPromise.then(list => {
        this.pendingCommands.set(command.commandId,
          { resolve, reject, commandId: command.commandId, request: command.request });
        return list.push(command);
      })
          .then(() => log.debug('Command accepted by server', command.commandId))
          .catch(err => {
            this.pendingCommands.delete(command.commandId);
            log.error('Failed to add a command to the session', err);
            if ((err.code == ResponseCodes.ACCESS_FORBIDDEN_FOR_IDENTITY || err.code === ResponseCodes.LIST_NOT_FOUND) && createSessionIfNotFound) {
              log.info('recreating session...');
              this.initialize();
              resolve(this.processCommand(action, params, false)); // second attempt
            } else {
              reject(new Error('Can\'t add command: ' + err.message));
            }
          });
    });
  }

  /**
   * @private
   */
  private processCommandResponse(entity) {
    if (entity.data.hasOwnProperty('response') &&
      entity.data.hasOwnProperty('commandId') &&
      this.pendingCommands.has(entity.data.commandId)
    ) {
      const data = entity.data;
      const commandId = data.commandId;
      if (data.response.status === ResponseCodes.HTTP_200_OK) {
        log.debug('Command succeeded: ', data);
        let resolve = this.pendingCommands.get(commandId).resolve;
        this.pendingCommands.delete(commandId);
        resolve(data.response);
      } else {
        log.error('Command failed: ', data);
        let reject = this.pendingCommands.get(commandId).reject;
        this.pendingCommands.delete(commandId);
        reject(new SessionError(data.response.statusText, data.response.status));
      }
    }
  }

  private getSessionContext(): any {
    return this.sessionStreamPromise
               .then(stream => stream.getContext());
  }

  async getSessionLinks(): Promise<SessionLinks> {
    let info = await this.sessionInfo.promise;
    return {
      publicChannelsUrl: this.config.baseUrl + info.links.publicChannelsUrl as string,
      myChannelsUrl: this.config.baseUrl + info.links.myChannelsUrl as string,
      typingUrl: this.config.baseUrl + info.links.typingUrl as string,
      syncListUrl: this.config.baseUrl + info.links.syncListUrl as string,
      usersUrl: this.config.baseUrl + info.links.usersUrl as string,
      mediaServiceUrl: info.links.mediaServiceUrl as string,
      messagesReceiptsUrl: this.config.baseUrl + info.links.messagesReceiptsUrl as string
    };
  }

  async getConversationsId(): Promise<string> {
    let info = await this.sessionInfo.promise;
    return info.channels;
  }

  async getMyConversationsId(): Promise<string> {
    let info = await this.sessionInfo.promise;
    return info.myChannels;
  }

  async getMaxUserInfosToSubscribe(): Promise<number> {
    let info = await this.sessionInfo.promise;
    return this.config.userInfosToSubscribeOverride
      || info.userInfosToSubscribe
      || this.config.userInfosToSubscribeDefault;
  }

  getUsersData() {
    return this.sessionInfo.promise.then(info => ({
      user: info.userInfo,
      identity: info.identity
    }));
  }

  async getConsumptionReportInterval(): Promise<number> {
    let context = await this.getSessionContext();
    let consumptionIntervalToUse = this.config.consumptionReportIntervalOverride
      || context.consumptionReportInterval
      || this.config.consumptionReportIntervalDefault;

    try {
      return toSeconds(parseDuration(consumptionIntervalToUse));
    } catch (e) {
      log.error(
        'Failed to parse consumption report interval', consumptionIntervalToUse,
        'using default value', this.config.consumptionReportIntervalDefault
      );
      return toSeconds(parseDuration(this.config.consumptionReportIntervalDefault));
    }
  }

  async getHttpCacheInterval(): Promise<number> {
    let context = await this.getSessionContext();
    let cacheIntervalToUse = this.config.httpCacheIntervalOverride
      || context.httpCacheInterval
      || this.config.httpCacheIntervalDefault;

    try {
      return toSeconds(parseDuration(cacheIntervalToUse));
    } catch (e) {
      log.error(
        'Failed to parse cache interval', cacheIntervalToUse,
        'using default value', this.config.httpCacheIntervalDefault
      );
      return toSeconds(parseDuration(this.config.httpCacheIntervalDefault));
    }
  }
}

export { SessionLinks, Session };
