namespace DetailedDeliveryReceipt {
  export type Status  = 'sent' | 'delivered' | 'failed' | 'read' | 'undelivered' | 'queued';

  export interface Descriptor {
    sid: string;
    message_sid: string;
    conversation_sid: string;
    channel_message_sid: string;
    participant_sid: string;
    status: Status;
    error_code: number | null;
    date_created: string;
    date_updated: string;
  }
}

/**
 * @classdesc Represents a delivery receipt of a {@link Message}.
 *
 * @property {String} sid - The unique identifier for Delivery Receipt
 * @property {String} messageSid - The unique identifier for Conversation Message
 * @property {String} conversationSid - The unique identifier for Conversation
 * @property {String} channelMessageSid - The unique identifier for the ‘channel’ message e.g WAxx for Whatsapp, SMxx for SMS
 * @property {String} participantSid - Participant's unique identifier
 * @property {DetailedDeliveryReceipt#Status} status - Message delivery status
 * @property {number | null} errorCode - Numeric error code mapped from Status callback code. Information about the error codes can be found
 * <a href="https://www.twilio.com/docs/sms/api/message-resource#delivery-related-errors">here</a>.
 * @property {String} dateCreated - When Delivery Receipt was created
 * @property {String} dateUpdated - When Delivery Receipt was updated
 */
export class DetailedDeliveryReceipt {
  sid: string;
  messageSid: string;
  conversationSid: string;
  channelMessageSid: string;
  participantSid: string;
  status: DetailedDeliveryReceipt.Status;
  errorCode: number | null;
  dateCreated: string;
  dateUpdated: string;

  /**
   * Signifies the message delivery status.
   * @typedef {('sent'|'delivered'|'failed'|'read'|'undelivered'|'queued')} DetailedDeliveryReceipt#Status
   */

  constructor(descriptor: DetailedDeliveryReceipt.Descriptor) {
    this.sid = descriptor.sid;
    this.messageSid = descriptor.message_sid;
    this.conversationSid = descriptor.conversation_sid;
    this.channelMessageSid = descriptor.channel_message_sid;
    this.participantSid = descriptor.participant_sid;
    this.status = descriptor.status || 'queued';
    this.errorCode = descriptor.error_code || 0;
    this.dateCreated = descriptor.date_created;
    this.dateUpdated = descriptor.date_updated;
  }
}
