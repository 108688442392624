import { McsClient, McsMedia } from 'twilio-mcs-client';

export interface MediaState {
  sid: string;
  filename?: string;
  contentType: string;
  size: number;
}

export interface MediaServices {
  mcsClient: McsClient;
}

/**
 * @classdesc A Media represents a media information for Message in a Conversation.
 * @property {String} contentType - content type of media
 * @property {String} sid - The server-assigned unique identifier for Media
 * @property {Number} size - Size of media, bytes
 * @property {String} [filename] - file name if present, null otherwise
 */
class Media {

  private state: MediaState;
  private services: MediaServices;
  private mcsMedia: McsMedia = null;

  constructor(data: MediaState, services: MediaServices) {

    this.services = services;

    this.state = {
      sid: data.sid,
      filename: data.filename,
      contentType: data.contentType,
      size: data.size
    };
  }

  public get sid(): string { return this.state.sid; }

  public get filename(): string {return this.state.filename; }

  public get contentType(): string { return this.state.contentType; }

  public get size(): number {return this.state.size; }

  /**
   * Returns direct content URL for the media.
   *
   * This URL is impermanent, it will expire in several minutes and cannot be cached.
   * If the URL becomes expired, you need to request a new one.
   * Each call to this function produces a new temporary URL.
   *
   * @returns {Promise<String>}
   */
  public async getContentTemporaryUrl() {
    if (!this.mcsMedia) {
      if (this.services.mcsClient) {
        this.mcsMedia = await this.services.mcsClient.get(this.state.sid);
      } else {
        throw new Error('Media Content Service is unavailable');
      }
    }
    return this.mcsMedia.getContentUrl();
  }
}

export { Media };
