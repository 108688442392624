import { EventEmitter } from 'events';
import { Logger } from './logger';
import { Configuration } from './configuration';

import { User } from './user';
import { Network } from './services/network';

import { Transport } from './interfaces/transport';
import { NotificationTypes } from './interfaces/notificationtypes';

import { SyncList } from './synclist';
import { Twilsock as TwilsockClient } from 'twilsock';
import { ChannelType, ConnectionState as NotificationConnectionState, Notifications as NotificationClient } from 'twilio-notifications';
import { SyncClient } from 'twilio-sync';
import { McsClient } from 'twilio-mcs-client';

import { Session } from './session';
import { Conversation, Conversations as ConversationsEntity } from './data/conversations';

import { Users } from './data/users';
import { TypingIndicator } from './services/typingindicator';
import { ReadHorizon } from './services/readhorizon';
import { Paginator } from './interfaces/paginator';
import { PushNotification } from './pushnotification';
import { deepClone, parseToNumber } from './util';
import { Participant } from './participant';
import { Message } from './message';
import { TelemetryEventDescription, TelemetryPoint } from 'twilsock/lib/services/telemetrytracker';
import { validateTypesAsync, validateTypes, custom, literal, nonEmptyString, pureObject, stringifyReceivedType, objectSchema } from 'twilio-sdk-type-validator';
import { version } from '../package.json';

const log = Logger.scope('Client');

const SDK_VERSION = version;

class ClientServices {
  session: Session;
  twilsockClient: TwilsockClient;
  users: Users;
  notificationClient: NotificationClient;
  //publicChannels: PublicConversations;
  //myConversations: UserConversations;
  network: Network;
  typingIndicator: TypingIndicator;
  syncClient: SyncClient;
  readHorizon: ReadHorizon;
  syncList: SyncList;
  mcsClient: McsClient;
  transport: Transport;
}

namespace Client {
  export type ConnectionState = NotificationConnectionState;

  export type NotificationsChannelType = ChannelType;

  export type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'silent' | null;

  export interface Options {
    region?: string;
    logLevel?: Client.LogLevel;
    productId?: string;
    twilsockClient?: TwilsockClient;
    transport?: Transport;
    notificationsClient?: NotificationClient;
    syncClient?: SyncClient;
    typingIndicatorTimeoutOverride?: number;
    consumptionReportIntervalOverride?: string;
    httpCacheIntervalOverride?: string;
    userInfosToSubscribeOverride?: number;
    retryWhenThrottledOverride?: boolean;
    backoffConfigOverride?: any;
    Chat?: any;
    Sync?: any;
    Notification?: any;
    Twilsock?: any;
    clientMetadata?: any;
  }

  export interface CreateConversationOptions {
    attributes?: any;
    friendlyName?: string;
    uniqueName?: string;
  }
}

/**
 * A Client is a starting point to access Twilio Conversations functionality.
 *
 * @property {Client#ConnectionState} connectionState - Client connection state
 * @property {Boolean} reachabilityEnabled - Client reachability state
 * @property {User} user - Information for logged in user
 * @property {String} version - Current version of Conversations client
 *
 * @fires Client#connectionError
 * @fires Client#connectionStateChanged
 * @fires Client#conversationAdded
 * @fires Client#conversationJoined
 * @fires Client#conversationLeft
 * @fires Client#conversationRemoved
 * @fires Client#conversationUpdated
 * @fires Client#participantJoined
 * @fires Client#participantLeft
 * @fires Client#participantUpdated
 * @fires Client#messageAdded
 * @fires Client#messageRemoved
 * @fires Client#messageUpdated
 * @fires Client#pushNotification
 * @fires Client#tokenAboutToExpire
 * @fires Client#tokenExpired
 * @fires Client#typingEnded
 * @fires Client#typingStarted
 * @fires Client#userSubscribed
 * @fires Client#userUnsubscribed
 * @fires Client#userUpdated
 */
class Client extends EventEmitter {
  public connectionState: Client.ConnectionState = 'connecting';
  private sessionPromise: Promise<any> = null;
  private conversationsPromise: Promise<any> = null;
  private fpaToken: string;
  private config: Configuration;
  private conversations: any;
  private options: any;
  private services: ClientServices;
  public static readonly version: string = SDK_VERSION;
  public readonly version: string = SDK_VERSION;
  private static readonly supportedPushChannels: Client.NotificationsChannelType[] = ['fcm', 'apn'];
  private static readonly supportedPushDataFields = {
    'conversation_sid': 'conversationSid',
    'message_sid': 'messageSid',
    'message_index': 'messageIndex'
  };

  /**
   * These options can be passed to Client constructor.
   * @typedef {Object} Client#ClientOptions
   * @property {String} [logLevel='error'] - The level of logging to enable. Valid options
   *   (from strictest to broadest): ['silent', 'error', 'warn', 'info', 'debug', 'trace']
   */

  /**
   * These options can be passed to {@link Client#createConversation}.
   * @typedef {Object} Client#CreateConversationOptions
   * @property {any} [attributes] - Any custom attributes to attach to the Conversation
   * @property {String} [friendlyName] - The non-unique display name of the Conversation
   * @property {String} [uniqueName] - The unique identifier of the Conversation
   */

  /**
   * Connection state of Client.
   * @typedef {('connecting'|'connected'|'disconnecting'|'disconnected'|'denied')} Client#ConnectionState
   */

  /**
   * Notifications channel type.
   * @typedef {('fcm'|'apn')} Client#NotificationsChannelType
   */

  private constructor(token: string, options?: Client.Options) {
    super();

    this.options = (options || {}) as any;
    if (!this.options.disableDeepClone) {
      let options = {
        ...this.options,
        transport: undefined,
        twilsockClient: undefined
      };

      options = deepClone(options);
      options.transport = this.options.transport;
      options.twilsockClient = this.options.twilsockClient;

      this.options = options;
    }
    this.options.logLevel = this.options.logLevel || 'silent';
    log.setLevel(this.options.logLevel);

    const productId = this.options.productId = 'ip_messaging';

    // Filling ClientMetadata
    this.options.clientMetadata = this.options.clientMetadata || {};
    if (!this.options.clientMetadata.hasOwnProperty('type')) {
      this.options.clientMetadata.type = 'conversations';
    }
    if (!this.options.clientMetadata.hasOwnProperty('sdk')) {
      this.options.clientMetadata.sdk = 'JS';
      this.options.clientMetadata.sdkv = SDK_VERSION;
    }

    // Enable session local storage for Sync
    this.options.Sync = this.options.Sync || {};
    if (typeof this.options.Sync.enableSessionStorage === 'undefined') {
      this.options.Sync.enableSessionStorage = true;
    }
    if (this.options.region) {
      this.options.Sync.region = this.options.region;
    }

    if (!token) {
      throw new Error('A valid Twilio token should be provided');
    }

    this.services = new ClientServices();
    this.config = new Configuration(this.options);

    this.options.twilsockClient = this.options.twilsockClient || new TwilsockClient(token, productId, this.options);
    this.options.transport = this.options.transport || this.options.twilsockClient;
    this.options.notificationsClient = this.options.notificationsClient || new NotificationClient(token, this.options);
    this.options.syncClient = this.options.syncClient || new SyncClient(token, this.options);

    this.services.syncClient = this.options.syncClient;
    this.services.transport = this.options.transport;
    this.services.twilsockClient = this.options.twilsockClient;
    this.services.notificationClient = this.options.notificationsClient;
    this.services.session = new Session(this.services, this.config);
    this.sessionPromise = this.services.session.initialize();

    this.services.network = new Network(this.config, this.services);

    this.services.users = new Users({
      session: this.services.session,
      network: this.services.network,
      syncClient: this.services.syncClient
    });
    this.services.users.on('userSubscribed', this.emit.bind(this, 'userSubscribed'));
    this.services.users.on('userUpdated',
      (args: User.UpdatedEventArgs) => this.emit('userUpdated', args));
    this.services.users.on('userUnsubscribed', this.emit.bind(this, 'userUnsubscribed'));

    this.services.twilsockClient.on('tokenAboutToExpire', ttl => this.emit('tokenAboutToExpire', ttl));
    this.services.twilsockClient.on('tokenExpired', () => this.emit('tokenExpired'));
    this.services.twilsockClient.on('connectionError', (error) => this.emit('connectionError', error));

    this.services.readHorizon = new ReadHorizon(this.services);
    this.services.typingIndicator = new TypingIndicator(this.config, {
      transport: this.services.twilsockClient,
      notificationClient: this.services.notificationClient
    }, this.getConversationBySid.bind(this));

    this.services.syncList = new SyncList(this.services);

    this.conversations = new ConversationsEntity(this.services);

    this.conversationsPromise = this.sessionPromise.then(() => {
      this.conversations.on('conversationAdded', this.emit.bind(this, 'conversationAdded'));
      this.conversations.on('conversationRemoved', this.emit.bind(this, 'conversationRemoved'));
      this.conversations.on('conversationJoined', this.emit.bind(this, 'conversationJoined'));
      this.conversations.on('conversationLeft', this.emit.bind(this, 'conversationLeft'));
      this.conversations.on('conversationUpdated',
        (args: Conversation.UpdatedEventArgs) => this.emit('conversationUpdated', args));

      this.conversations.on('participantJoined', this.emit.bind(this, 'participantJoined'));
      this.conversations.on('participantLeft', this.emit.bind(this, 'participantLeft'));
      this.conversations.on('participantUpdated',
        (args: Participant.UpdatedEventArgs) => this.emit('participantUpdated', args));

      this.conversations.on('messageAdded', this.emit.bind(this, 'messageAdded'));
      this.conversations.on('messageUpdated',
        (args: Message.UpdatedEventArgs) => this.emit('messageUpdated', args));
      this.conversations.on('messageRemoved', this.emit.bind(this, 'messageRemoved'));

      this.conversations.on('typingStarted', this.emit.bind(this, 'typingStarted'));
      this.conversations.on('typingEnded', this.emit.bind(this, 'typingEnded'));

      return this.conversations.fetchConversations();
    }).then(() => this.conversations);

    this.services.notificationClient.on('connectionStateChanged', (state: Client.ConnectionState) => {
      let changedConnectionState = null;
      switch (state) {
        case 'connected':
          changedConnectionState = 'connected';
          break;
        case 'denied':
          changedConnectionState = 'denied';
          break;
        case 'disconnecting':
          changedConnectionState = 'disconnecting';
          break;
        case 'disconnected':
          changedConnectionState = 'disconnected';
          break;
        default:
          changedConnectionState = 'connecting';
      }
      if (changedConnectionState !== this.connectionState) {
        this.connectionState = changedConnectionState;
        this.emit('connectionStateChanged', this.connectionState);
      }
    });

    this.fpaToken = token;
  }

  /**
   * Factory method to create Conversations client instance.
   *
   * @param {String} token - Access token
   * @param {Client#ClientOptions} [options] - Options to customize the Client
   * @returns {Promise<Client>}
   */
  @validateTypesAsync('string', ['undefined', pureObject])
  static async create(token: string, options?: Client.Options): Promise<Client> {
    let client = new Client(token, options);

    const startupEvent = 'conversations.client.startup';

    client.services.twilsockClient.addPartialTelemetryEvent(new TelemetryEventDescription(
      startupEvent,
      'Conversations client startup',
      new Date()
    ), startupEvent, TelemetryPoint.Start);

    await client.initialize();

    client.services.twilsockClient.addPartialTelemetryEvent(
      new TelemetryEventDescription('', '', new Date()),
      startupEvent,
      TelemetryPoint.End);

    return client;
  }

  public get user(): User { return this.services.users.myself; }

  public get reachabilityEnabled(): boolean { return this.services.session.reachabilityEnabled; }

  public get token(): string { return this.fpaToken; }

  private subscribeToPushNotifications(channelType: Client.NotificationsChannelType) {
    let subscriptions: Promise<any>[] = [];
    [NotificationTypes.NEW_MESSAGE,
      NotificationTypes.ADDED_TO_CONVERSATION,
      NotificationTypes.REMOVED_FROM_CONVERSATION,
      NotificationTypes.TYPING_INDICATOR,
      NotificationTypes.CONSUMPTION_UPDATE]
      .forEach(messageType => {
        subscriptions.push(this.services.notificationClient.subscribe(messageType, channelType));
      });
    return Promise.all(subscriptions);
  }

  private unsubscribeFromPushNotifications(channelType: Client.NotificationsChannelType) {
    let subscriptions: Promise<any>[] = [];
    [NotificationTypes.NEW_MESSAGE,
      NotificationTypes.ADDED_TO_CONVERSATION,
      NotificationTypes.REMOVED_FROM_CONVERSATION,
      NotificationTypes.TYPING_INDICATOR,
      NotificationTypes.CONSUMPTION_UPDATE]
      .forEach(messageType => {
        subscriptions.push(this.services.notificationClient.unsubscribe(messageType, channelType));
      });
    return Promise.all(subscriptions);
  }

  private async initialize() {
    await this.sessionPromise;

    Client.supportedPushChannels.forEach(channelType => this.subscribeToPushNotifications(channelType));

    let links = await this.services.session.getSessionLinks();

    let options = Object.assign(this.options);
    options.transport = null;
    this.services.mcsClient = new McsClient(this.fpaToken, links.mediaServiceUrl, options);

    await this.services.typingIndicator.initialize();
  }

  /**
   * Gracefully shutting down library instance.
   * @public
   * @returns {Promise<void>}
   */
  async shutdown(): Promise<void> {
    await this.services.twilsockClient.disconnect();
  }

  /**
   * Update the token used by the Client and re-register with Conversations services.
   * @param {String} token - Access token
   * @public
   * @returns {Promise<Client>}
   */
  @validateTypesAsync(nonEmptyString)
  async updateToken(token: string): Promise<Client> {
    log.info('updateToken');

    if (this.fpaToken === token) {
      return this;
    }

    await this.services.twilsockClient.updateToken(token)
      .then(() => this.fpaToken = token)
      .then(() => this.services.mcsClient.updateToken(token))
      .then(() => this.sessionPromise);

    return this;
  }

  /**
   * Get a known Conversation by its SID.
   * @param {String} conversationSid - Conversation sid
   * @returns {Promise<Conversation>}
   */
  @validateTypesAsync(nonEmptyString)
  async getConversationBySid(conversationSid: string): Promise<Conversation> {
    await this.conversations.syncListRead.promise;
    let conversation = await this.conversations.getConversation(conversationSid);

    if (!conversation) {
      conversation = await this.conversations.getWhisperConversation(conversationSid);
    }

    if (!conversation) {
      throw new Error(`Conversation with SID ${conversationSid} is not found.`);
    }

    return conversation;
  }

  /**
   * Get a known Conversation by its unique identifier name.
   * @param {String} uniqueName - The unique identifier name of the Conversation to get
   * @returns {Promise<Conversation>}
   */
  @validateTypesAsync(nonEmptyString)
  async getConversationByUniqueName(uniqueName: string): Promise<Conversation> {
    await this.conversations.syncListRead.promise;
    const conversation = await this.conversations.getConversationByUniqueName(uniqueName);

    if (!conversation) {
      throw new Error(`Conversation with unique name ${uniqueName} is not found.`);
    }

    return conversation;
  }

  /**
   * Get the current list of all subscribed Conversations.
   * @returns {Promise<Paginator<Conversation>>}
   */
  getSubscribedConversations(args?): Promise<Paginator<Conversation>> {
    return this.conversationsPromise.then(conversations => conversations.getConversations(args));
  }

  /**
   * Create a Conversation on the server and subscribe to its events.
   * The default is a Conversation with an empty friendlyName.
   * @param {Client#CreateConversationOptions} [options] - Options for the Conversation
   * @returns {Promise<Conversation>}
   */
  @validateTypesAsync([
    'undefined',
    objectSchema('conversation options', {
      friendlyName: ['string', 'undefined'],
      isPrivate: ['boolean', 'undefined'],
      uniqueName: ['string', 'undefined']
    })
  ])
  createConversation(options?: Client.CreateConversationOptions): Promise<Conversation> {
    options = options || {};
    return this.conversationsPromise.then(conversationsEntity => conversationsEntity.addConversation(options));
  }

  /**
   * Registers for push notifications.
   * @param {Client#NotificationsChannelType} channelType - 'apn' and 'fcm' are supported
   * @param {string} registrationId - Push notification id provided by the platform
   * @returns {Promise<void>}
   */
  @validateTypesAsync(literal('fcm', 'apn'), 'string')
  async setPushRegistrationId(channelType: Client.NotificationsChannelType, registrationId: string): Promise<void> {
    await this.subscribeToPushNotifications(channelType)
      .then(() => {
        return this.services.notificationClient.setPushRegistrationId(registrationId, channelType);
      });
  }

  /**
   * Unregisters from push notifications.
   * @param {Client#NotificationsChannelType} channelType - 'apn' and 'fcm' are supported
   * @returns {Promise<void>}
   */
  @validateTypesAsync(literal('fcm', 'apn'))
  async unsetPushRegistrationId(channelType: Client.NotificationsChannelType): Promise<void> {
    if (Client.supportedPushChannels.indexOf(channelType) === -1) {
      throw new Error('Invalid or unsupported channelType: ' + channelType);
    }
    await this.unsubscribeFromPushNotifications(channelType);
  }

  private static parsePushNotificationChatData(data: Object): Object {
    let result: Object = {};
    for (let key in Client.supportedPushDataFields) {
      if (typeof data[key] !== 'undefined' && data[key] !== null) {
        if (key === 'message_index') {
          if (parseToNumber(data[key]) !== null) {
            result[Client.supportedPushDataFields[key]] = Number(data[key]);
          }
        } else {
          result[Client.supportedPushDataFields[key]] = data[key];
        }
      }
    }

    return result;
  }

  /**
   * Static method for push notification payload parsing. Returns parsed push as {@link PushNotification} object
   * @param {Object} notificationPayload - Push notification payload
   * @returns {PushNotification|Error}
   */
  @validateTypes(pureObject)
  static parsePushNotification(notificationPayload): PushNotification {
    log.debug('parsePushNotification, notificationPayload=', notificationPayload);

    // APNS specifics
    if (typeof notificationPayload.aps !== 'undefined') {
      if (!notificationPayload.twi_message_type) {
        throw new Error('Provided push notification payload does not contain Programmable Chat push notification type');
      }

      let data = Client.parsePushNotificationChatData(notificationPayload);

      let apsPayload = notificationPayload.aps;
      let body: string = null;
      let title: string = null;
      if (typeof apsPayload.alert === 'string') {
        body = apsPayload.alert || null;
      } else {
        body = apsPayload.alert.body || null;
        title = apsPayload.alert.title || null;
      }

      return new PushNotification({
        title: title,
        body: body,
        sound: apsPayload.sound || null,
        badge: apsPayload.badge || null,
        action: apsPayload.category || null,
        type: notificationPayload.twi_message_type,
        data: data
      });
    }

    // FCM specifics
    if (typeof notificationPayload.data !== 'undefined') {
      let dataPayload = notificationPayload.data;
      if (!dataPayload.twi_message_type) {
        throw new Error('Provided push notification payload does not contain Programmable Chat push notification type');
      }

      let data = Client.parsePushNotificationChatData(notificationPayload.data);
      return new PushNotification({
        title: dataPayload.twi_title || null,
        body: dataPayload.twi_body || null,
        sound: dataPayload.twi_sound || null,
        badge: null,
        action: dataPayload.twi_action || null,
        type: dataPayload.twi_message_type,
        data: data
      });
    }

    throw new Error('Provided push notification payload is not Programmable Chat notification');
  }

  public parsePushNotification = Client.parsePushNotification;

  /**
   * Handle push notification payload parsing and emits event {@link Client#event:pushNotification} on this {@link Client} instance.
   * @param {Object} notificationPayload - Push notification payload
   * @returns {Promise<void>}
   */
  @validateTypesAsync(pureObject)
  async handlePushNotification(notificationPayload): Promise<void> {
    log.debug('handlePushNotification, notificationPayload=', notificationPayload);
    this.emit('pushNotification', Client.parsePushNotification(notificationPayload));
  }

  /**
   * Gets user for given identity, if it's in subscribed list - then return the user object from it,
   * if not - then subscribes and adds user to the subscribed list.
   * @param {String} identity - Identity of User
   * @returns {Promise<User>} Fully initialized user
   */
  @validateTypesAsync(nonEmptyString)
  public getUser(identity: string): Promise<User> {
    return this.services.users.getUser(identity);
  }

  /**
   * @returns {Promise<Array<User>>} List of subscribed User objects
   */
  public async getSubscribedUsers(): Promise<Array<User>> {
    return this.services.users.getSubscribedUsers();
  }
}

export { User, Client, PushNotification };
export default Client;

/**
 * Fired when a Conversation becomes visible to the Client. The event is also triggered when the client creates a new Conversation.
 * Fired for all conversations Client has joined.
 * @event Client#conversationAdded
 * @type {Conversation}
 */
/**
 * Fired when the Client joins a Conversation.
 * @event Client#conversationJoined
 * @type {Conversation}
 */
/**
 * Fired when the Client leaves a Conversation.
 * @event Client#conversationLeft
 * @type {Conversation}
 */
/**
 * Fired when a Conversation is no longer visible to the Client.
 * @event Client#conversationRemoved
 * @type {Conversation}
 */
/**
 * Fired when a Conversation's attributes or metadata have been updated.
 * During Conversation's {@link Client.create| creation and initialization} this event might be fired multiple times
 * for same joined or created Conversation as new data is arriving from different sources.
 * @event Client#conversationUpdated
 * @type {Object}
 * @property {Conversation} conversation - Updated Conversation
 * @property {Conversation#UpdateReason[]} updateReasons - Array of Conversation's updated event reasons
 */
/**
 * Fired when Client's connection state has been changed.
 * @event Client#connectionStateChanged
 * @type {Client#ConnectionState}
 */
/**
 * Fired when a Participant has joined the Conversation.
 * @event Client#participantJoined
 * @type {Participant}
 */
/**
 * Fired when a Participant has left the Conversation.
 * @event Client#participantLeft
 * @type {Participant}
 */
/**
 * Fired when a Participant's fields has been updated.
 * @event Client#participantUpdated
 * @type {Object}
 * @property {Participant} participant - Updated Participant
 * @property {Participant#UpdateReason[]} updateReasons - Array of Participant's updated event reasons
 */
/**
 * Fired when a new Message has been added to the Conversation on the server.
 * @event Client#messageAdded
 * @type {Message}
 */
/**
 * Fired when Message is removed from Conversation's message list.
 * @event Client#messageRemoved
 * @type {Message}
 */
/**
 * Fired when an existing Message's fields are updated with new values.
 * @event Client#messageUpdated
 * @type {Object}
 * @property {Message} message - Updated Message
 * @property {Message#UpdateReason[]} updateReasons - Array of Message's updated event reasons
 */
/**
 * Fired when token is about to expire and needs to be updated.
 * @event Client#tokenAboutToExpire
 * @type {void}
 */
/**
 * Fired when token is expired.
 * @event Client#tokenExpired
 * @type {void}
 */
/**
 * Fired when a Participant has stopped typing.
 * @event Client#typingEnded
 * @type {Participant}
 */
/**
 * Fired when a Participant has started typing.
 * @event Client#typingStarted
 * @type {Participant}
 */
/**
 * Fired when client received (and parsed) push notification via one of push channels (apn or fcm).
 * @event Client#pushNotification
 * @type {PushNotification}
 */
/**
 * Fired when the Client is subscribed to a User.
 * @event Client#userSubscribed
 * @type {User}
 */
/**
 * Fired when the Client is unsubscribed from a User.
 * @event Client#userUnsubscribed
 * @type {User}
 */
/**
 * Fired when the User's properties or reachability status have been updated.
 * @event Client#userUpdated
 * @type {Object}
 * @property {User} user - Updated User
 * @property {User#UpdateReason[]} updateReasons - Array of User's updated event reasons
 */
/**
 * Fired when connection is interrupted by unexpected reason
 * @event Client#connectionError
 * @type {Object}
 * @property {Boolean} terminal - twilsock will stop connection attempts
 * @property {String} message - root cause
 * @property {Number} [httpStatusCode] - http status code if available
 * @property {Number} [errorCode] - Twilio public error code if available
 */
