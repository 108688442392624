namespace AggregatedDeliveryReceipt {
  export type DeliveryAmount = 'none' | 'some' | 'all';

  export interface AggregatedDeliveryDescriptor {
    total: number;
    delivered: DeliveryAmount;
    failed: DeliveryAmount;
    read: DeliveryAmount;
    sent: DeliveryAmount;
    undelivered: DeliveryAmount;
  }
}

/**
 * @classdesc Contains aggregated information about a {@link Message}'s delivery statuses across all {@link Participant}s
 * of a {@link Conversation}.
 *
 * At any moment during delivering message to a {@link Participant} the message can have zero or more of following
 * delivery statuses:
 *
 * <ul><li>
 * Message considered as <b>sent</b> to a participant, if the nearest upstream carrier accepted the message.
 * </li><li>
 * Message considered as <b>delivered</b> to a participant, if Twilio has received confirmation of message
 * delivery from the upstream carrier, and, where available, the destination handset.
 * </li><li>
 * Message considered as <b>undelivered</b> to a participant, if Twilio has received a delivery receipt
 * indicating that the message was not delivered. This can happen for many reasons including carrier content
 * filtering and the availability of the destination handset.
 * </li><li>
 * Message considered as <b>read</b> by a participant, if the message has been delivered and opened by the
 * recipient in the conversation. The recipient must have enabled read receipts.
 * </li><li>
 * Message considered as <b>failed</b> to be delivered to a participant if the message could not be sent.
 * This can happen for various reasons including queue overflows, account suspensions and media
 * errors (in the case of MMS for instance).
 *</li></ul>
 *
 * {@link AggregatedDeliveryReceipt} class contains aggregated value {@link AggregatedDeliveryReceipt#DeliveryAmount} for each delivery status.
 *
 * @property {number} total - Maximum number of delivery events expected for the message
 * @property {AggregatedDeliveryReceipt#DeliveryAmount} sent - Amount of participants that have <b>sent</b> delivery status for the message.
 * @property {AggregatedDeliveryReceipt#DeliveryAmount} delivered - Amount of participants that have <b>delivered</b> delivery status
 *   for the message.
 * @property {AggregatedDeliveryReceipt#DeliveryAmount} read - Amount of participants that have <b>read</b> delivery status for the message.
 * @property {AggregatedDeliveryReceipt#DeliveryAmount} undelivered - Amount of participants that have <b>undelivered</b> delivery status
 *   for the message.
 * @property {AggregatedDeliveryReceipt#DeliveryAmount} failed - Amount of participants that have <b>failed</b> delivery status for the message.
 */
class AggregatedDeliveryReceipt {

  private state: AggregatedDeliveryReceipt.AggregatedDeliveryDescriptor;

  /**
   * Signifies amount of participants which have the status for the message.
   * @typedef {('none'|'some'|'all')} AggregatedDeliveryReceipt#DeliveryAmount
   */

  constructor(data: AggregatedDeliveryReceipt.AggregatedDeliveryDescriptor) {
    this.state = data;
  }

  /**
   * @return Maximum number of delivery events expected for the message.
   */
  public get total(): number {
    return this.state.total;
  }

  /**
   * Message considered as <b>sent</b> to a participant, if the nearest upstream carrier accepted the message.
   *
   * @return {@link DeliveryAmount} of participants that have <b>sent</b> delivery status for the message.
   */
  public get sent(): AggregatedDeliveryReceipt.DeliveryAmount {
    return this.state.sent;
  }

  /**
   * Message considered as <b>delivered</b> to a participant, if Twilio has received confirmation of message
   * delivery from the upstream carrier, and, where available, the destination handset.
   *
   * @return {@link DeliveryAmount} of participants that have <b>delivered</b> delivery status for the message.
   */
  public get delivered(): AggregatedDeliveryReceipt.DeliveryAmount {
    return this.state.delivered;
  }

  /**
   * Message considered as <b>read</b> by a participant, if the message has been delivered and opened by the
   * recipient in the conversation. The recipient must have enabled read receipts.
   *
   * @return {@link DeliveryAmount} of participants that have <b>read</b> delivery status for the message.
   */
  public get read(): AggregatedDeliveryReceipt.DeliveryAmount {
    return this.state.read;
  }

  /**
   * Message considered as <b>undelivered</b> to a participant, if Twilio has received a delivery receipt
   * indicating that the message was not delivered. This can happen for many reasons including carrier content
   * filtering and the availability of the destination handset.
   *
   * @return {@link DeliveryAmount} of participants that have <b>undelivered</b> delivery status for the message.
   */
  public get undelivered(): AggregatedDeliveryReceipt.DeliveryAmount {
    return this.state.undelivered;
  }

  /**
   * Message considered as <b>failed</b> to be delivered to a participant if the message could not be sent.
   * This can happen for various reasons including queue overflows, account suspensions and media
   * errors (in the case of MMS for instance). Twilio does not charge you for failed messages.
   *
   * @return {@link DeliveryAmount} of participants that have <b>failed</b> delivery status for the message.
   */
  public get failed(): AggregatedDeliveryReceipt.DeliveryAmount {
    return this.state.failed;
  }

  _update(data: AggregatedDeliveryReceipt.AggregatedDeliveryDescriptor): void {
    this.state = data;
  }

  _isEquals(data: AggregatedDeliveryReceipt.AggregatedDeliveryDescriptor): boolean {
    const isTotalSame = this.total === data.total;
    const isSentSame = this.sent === data.sent;
    const isDeliveredSame = this.delivered === data.delivered;
    const isReadSame = this.read === data.read;
    const isUndeliveredSame = this.undelivered === data.undelivered;
    const isFailedSame = this.failed === data.failed;

    return isTotalSame && isSentSame && isDeliveredSame && isReadSame && isUndeliveredSame && isFailedSame;
  }
}

export { AggregatedDeliveryReceipt };
