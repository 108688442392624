import { EventEmitter } from 'events';
import { Users } from './data/users';
import { User } from './user';
import { isDeepEqual, parseTime, parseAttributes } from './util';
import { Logger } from './logger';
import { Session } from './session';
import { Conversation } from './conversation';
import { validateTypesAsync, literal } from 'twilio-sdk-type-validator';

const log = Logger.scope('Participant');

interface ParticipantDescriptor {
  attributes?: Object;
  dateCreated: any;
  dateUpdated: any;
  identity: string;
  roleSid?: string;
  lastConsumedMessageIndex: number;
  lastConsumptionTimestamp: number;
  type: Participant.Type;
  userInfo: string;
}

interface ParticipantState {
  attributes: any;
  dateCreated: Date;
  dateUpdated: Date;
  identity: string;
  isTyping: boolean;
  lastReadMessageIndex: number | null;
  lastReadTimestamp: Date;
  roleSid: string;
  sid: string;
  type: Participant.Type;
  typingTimeout: any;
  userInfo: string;
}

export interface ParticipantServices {
  users: Users;
  session: Session;
}

namespace Participant {
  export type UpdateReason = 'attributes' | 'dateCreated' | 'dateUpdated' | 'roleSid' | 'lastReadMessageIndex' | 'lastReadTimestamp';

  export type Type = 'chat' | 'sms' | 'whatsapp';

  export interface UpdatedEventArgs {
    participant: Participant;
    updateReasons: Participant.UpdateReason[];
  }
}

/**
 * @classdesc A Participant represents a remote Client in a Conversation.
 * @property {any} attributes - Object with custom attributes for Participant
 * @property {Conversation} conversation - The Conversation the remote Client is a Participant of
 * @property {Date} dateCreated - The Date this Participant was created
 * @property {Date} dateUpdated - The Date this Participant was last updated
 * @property {String} identity - The identity of the remote Client
 * @property {Boolean} isTyping - Whether or not this Participant is currently typing
   * @property {Number|null} lastReadMessageIndex - Latest read Message index by this Participant.
 * Note that just retrieving messages on a client endpoint does not mean that messages are read,
 * please consider reading about [Read Horizon feature]{@link https://www.twilio.com/docs/api/chat/guides/consumption-horizon}
 * to find out how to mark messages as read.
 * @property {Date} lastReadTimestamp - Date when Participant has updated his read horizon
 * @property {String} sid - The server-assigned unique identifier for the Participant
 * @property {Participant#Type} type - The type of Participant
 * @fires Participant#typingEnded
 * @fires Participant#typingStarted
 * @fires Participant#updated
 */
class Participant extends EventEmitter {
  private state: ParticipantState;
  private services: ParticipantServices;
  public readonly conversation: Conversation;

  public get sid(): string { return this.state.sid; }

  public get attributes(): Object { return this.state.attributes; }

  public get dateCreated(): Date { return this.state.dateCreated; }

  public get dateUpdated(): Date { return this.state.dateUpdated; }

  public get identity(): string { return this.state.identity; }

  public get isTyping(): boolean { return this.state.isTyping; }

  public get lastReadMessageIndex(): number | null { return this.state.lastReadMessageIndex; }

  public get lastReadTimestamp(): Date { return this.state.lastReadTimestamp; }

  public get roleSid(): string { return this.state.roleSid; }

  public get type(): Participant.Type { return this.state.type; }

  /**
   * The update reason for <code>updated</code> event emitted on Participant
   * @typedef {('attributes' | 'dateCreated' | 'dateUpdated' | 'roleSid' |
    'lastReadMessageIndex' | 'lastReadTimestamp')} Participant#UpdateReason
   */

  /**
   * The type of Participant
   * @typedef {('chat' | 'sms' | 'whatsapp')} Participant#Type
   */

  constructor(services: ParticipantServices, conversation: Conversation, data: ParticipantDescriptor, sid: string) {
    super();

    this.conversation = conversation;
    this.services = services;
    this.state = {
      attributes: parseAttributes(data.attributes,
        'Retrieved malformed attributes from the server for participant: ' + sid,
        log),
      dateCreated: data.dateCreated ? parseTime(data.dateCreated) : null,
      dateUpdated: data.dateCreated ? parseTime(data.dateUpdated) : null,
      sid: sid,
      typingTimeout: null,
      isTyping: false,
      identity: data.identity || null,
      roleSid: data.roleSid || null,
      lastReadMessageIndex: Number.isInteger(data.lastConsumedMessageIndex) ? data.lastConsumedMessageIndex : null,
      lastReadTimestamp: data.lastConsumptionTimestamp ? parseTime(data.lastConsumptionTimestamp) : null,
      type: data.type || 'chat',
      userInfo: data.userInfo
    };

    if (!data.identity && !data.type) {
      throw new Error('Received invalid Participant object from server: Missing identity or type of Participant.');
    }
  }

  /**
   * Private method used to start or reset the typing indicator timeout (with event emitting)
   * @private
   */
  _startTyping(timeout) {
    clearTimeout(this.state.typingTimeout);

    this.state.isTyping = true;
    this.emit('typingStarted', this);
    this.conversation.emit('typingStarted', this);

    this.state.typingTimeout = setTimeout(() => this._endTyping(), timeout);
    return this;
  }

  /**
   * Private method function used to stop typing indicator (with event emitting)
   * @private
   */
  _endTyping() {
    if (!this.state.typingTimeout) { return; }

    this.state.isTyping = false;
    this.emit('typingEnded', this);
    this.conversation.emit('typingEnded', this);

    clearInterval(this.state.typingTimeout);
    this.state.typingTimeout = null;
  }

  /**
   * Private method function used update local object's property roleSid with new value
   * @private
   */
  _update(data) {
    let updateReasons: Participant.UpdateReason[] = [];

    let updateAttributes =
      parseAttributes(
        data.attributes,
        'Retrieved malformed attributes from the server for participant: ' + this.state.sid,
        log);

    if (data.attributes && !isDeepEqual(this.state.attributes, updateAttributes)) {
      this.state.attributes = updateAttributes;
      updateReasons.push('attributes');
    }

    let updatedDateUpdated = parseTime(data.dateUpdated);
    if (data.dateUpdated &&
      updatedDateUpdated.getTime() !== (this.state.dateUpdated && this.state.dateUpdated.getTime())) {
      this.state.dateUpdated = updatedDateUpdated;
      updateReasons.push('dateUpdated');
    }

    let updatedDateCreated = parseTime(data.dateCreated);
    if (data.dateCreated &&
      updatedDateCreated.getTime() !== (this.state.dateCreated && this.state.dateCreated.getTime())) {
      this.state.dateCreated = updatedDateCreated;
      updateReasons.push('dateCreated');
    }

    if (data.roleSid && this.state.roleSid !== data.roleSid) {
      this.state.roleSid = data.roleSid;
      updateReasons.push('roleSid');
    }

    if ((Number.isInteger(data.lastConsumedMessageIndex) || data.lastConsumedMessageIndex === null)
      && this.state.lastReadMessageIndex !== data.lastConsumedMessageIndex) {
      this.state.lastReadMessageIndex = data.lastConsumedMessageIndex;
      updateReasons.push('lastReadMessageIndex');
    }

    if (data.lastConsumptionTimestamp) {
      let lastReadTimestamp = new Date(data.lastConsumptionTimestamp);
      if (!this.state.lastReadTimestamp ||
        this.state.lastReadTimestamp.getTime() !== lastReadTimestamp.getTime()) {
        this.state.lastReadTimestamp = lastReadTimestamp;
        updateReasons.push('lastReadTimestamp');
      }
    }

    if (updateReasons.length > 0) {
      this.emit('updated', { participant: this, updateReasons: updateReasons });
    }

    return this;
  }

  /**
   * Gets User for this participant and subscribes to it. Supported only for <code>chat</code> type of Participants
   * @returns {Promise<User>}
   */
  async getUser(): Promise<User> {
    if (this.type != 'chat') {
      throw new Error('Getting User is not supported for this Participant type: ' + this.type);
    }

    return this.services.users.getUser(this.state.identity, this.state.userInfo);
  }

  /**
   * Remove Participant from the Conversation.
   * @returns {Promise<void>}
   */
  async remove() {
    return this.conversation.removeParticipant(this);
  }

  /**
   * Edit participant attributes.
   * @param {any} attributes new attributes for Participant.
   * @returns {Promise<Participant>}
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  async updateAttributes(attributes: any): Promise<Participant> {
    await this.services.session.addCommand('editMemberAttributes', {
      channelSid: this.conversation.sid,
      memberSid: this.sid,
      attributes: JSON.stringify(attributes)
    });

    return this;
  }
}

export { ParticipantDescriptor, Participant };

/**
 * Fired when Participant started to type.
 * @event Participant#typingStarted
 * @type {Participant}
 */

/**
 * Fired when Participant ended to type.
 * @event Participant#typingEnded
 * @type {Participant}
 */

/**
 * Fired when Participant's fields has been updated.
 * @event Participant#updated
 * @type {Object}
 * @property {Participant} participant - Updated Participant
 * @property {Participant#UpdateReason[]} updateReasons - Array of Participant's updated event reasons
 */
