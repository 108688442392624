'use strict';

namespace PushNotification {
  export type Type = 'twilio.conversations.new_message' | 'twilio.conversations.added_to_conversation'
    | 'twilio.conversations.removed_from_conversation';

  export interface Descriptor {
    title: string;
    body: string;
    sound: string;
    badge: number;
    action: string;
    type: PushNotification.Type;
    data: Object;
  }
}

/**
 * @classdesc Push notification representation within Conversations Client
 * @property {String} [action] - Notification action (`click_action` in FCM/GCM terms and `category` in APN terms)
 * @property {Number} [badge] - Number for the badge
 * @property {String} body - Notification text
 * @property {PushNotification#ConversationData} data - Additional Conversation data
 * @property {String} [sound] - Notification sound
 * @property {String} [title] - Notification title
 * @property {PushNotification#NotificationType} type - Notification type
 */
class PushNotification {
  public readonly title: string;
  public readonly body: string;
  public readonly sound: string;
  public readonly badge: number;
  public readonly action: string;
  public readonly type: PushNotification.Type;
  public readonly data: Object;

  /**
   * Conversation push notification type
   * @typedef {('twilio.conversations.new_message' | 'twilio.conversations.added_to_conversation'
   | 'twilio.conversations.removed_from_conversation')} PushNotification#NotificationType
   */

  /**
   * Additional Conversations data for given Push Notification
   * @typedef {Object} PushNotification#ConversationData
   * @property {String} [conversationSid] - SID of Conversation
   * @property {Number} [messageIndex] - Index of Message in Conversation
   * @property {String} [messageSid] - SID of Message
   */

  /**
   * @param {PushNotification.Descriptor} data - initial data for PushNotification
   */
  constructor(data: PushNotification.Descriptor) {
    this.title = data.title || null;
    this.body = data.body || null;
    this.sound = data.sound || null;
    this.badge = data.badge || null;
    this.action = data.action || null;
    this.type = data.type || null;
    this.data = data.data || {};
  }

}

export { PushNotification };
